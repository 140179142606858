/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Collapse, Button, Popover, PopoverBody } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import store from 'store2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './loading';
import { AppContext } from '../context';

import {
    getResourcePlanAppData,
    getProjectsAndResources,
    searchUsers,
    updateRoleResources,
    markProjectReady,
    finalizeProject,
    getUserProjects,
} from '../utils/api';

const StyledWrapper = styled.div`
    margin: 2rem 0;
    .label-totals {
        min-width: 200px;
        text-align: right;
        & + .label-totals {
            margin-left: 2rem;
        }
    }
    .franchise-toggle {
        cursor: pointer;
        background-color: #f5f5f5;
        h4 {
            font-weight: normal;
        }
    }
    .project {
        & + .project {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: solid 1px #cccccc;
        }
    }
    .roles {
        margin: 1rem 0;
        .roles-column {
            width: 200px;
        }
        h5 {
            font-size: 15px;
            text-align: center;
        }
        .roles-yes {
            background-color: #cccccc;
            border-radius: 4px;
            min-height: 65px;
        }
        .roles-no {
        }
    }
    .user {
        margin: 4px;
        display: inline-block;
        position: relative;
        .remove-user {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 4px;
            right: 4px;
            cursor: pointer;
            color: #cccccc;
            &:hover {
                color: #4090ee;
            }
        }
        > div {
            background-color: white;
            border-radius: 4px;
            min-width: 192px;
            border: solid 1px #cccccc;
            padding: 4px;
        }
        .profile {
            width: 47px;
            height: 47px;
            border-radius: 4px;
            background: #cccccc no-repeat center/cover;
            padding: 4px;
            margin-right: 4px;
        }
        .lock {
            width: 20px;
            height: 20px;
            cursor: pointer;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
            color: white;
            opacity: 0.5;
            &.locked {
                color: #4090ee;
                opacity: 1;
            }
        }
        .name {
            font-weight: bold;
        }
        .name,
        .projects {
            font-size: 0.8rem;
            font-style: italic;
        }
    }
    .close-info {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
        color: #cccccc;
        &:hover {
            color: #4090ee;
        }
    }
    .thinking {
        background: rgba(255, 255, 255, 0.75) url(/img/loading.svg) no-repeat center/contain;
    }
    hr {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .add-user {
        height: 60px;
        .react-select__control {
            width: 192px;
            margin: 0 4px;
        }
    }
    .react-select__control {
        display: inline-flex;
        cursor: pointer;
        .react-select__single-value,
        .react-select__placeholder {
            position: relative;
            top: 0;
            transform: none;
        }
    }
    .react-select__option {
        cursor: pointer;
    }
    .select-filter {
        margin-left: 1.5rem;
        .react-select__value-container {
            padding-left: 1rem;
        }
        .react-select__control {
            display: inline-flex;
            background-color: #4090ee;
            color: white;
            border-color: #4090ee;
            border-radius: 2rem;
            .react-select__dropdown-indicator {
                color: white;
                margin-left: 1rem;
            }
            .react-select__indicator-separator {
                display: none;
            }
        }
        .react-select__menu {
            width: 200%;
            .react-select__option--is-selected {
                background-color: inherit;
                color: inherit;
            }
            .react-select__option:hover {
                background-color: #deebff;
            }
        }
    }
    .react-select__input {
        // opacity: 0.01;
    }
    .roles-column .react-select__menu {
        margin-top: -8px;
    }
`;

const Resources = () => {
    const { year, quarter } = useParams();
    const history = useHistory();
    const appData = useContext(AppContext);
    const container = useRef();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [resourcePlannerAppData, setResourcePlannerAppData] = useState();
    const [fetchingProjectsResources, setFetchingProjectsResources] = useState(true);
    const [projectsResources, setProjectsResources] = useState();
    const [franchisesToggled, setFranchisesToggled] = useState(
        store.get('franchisesToggled') || []
    );
    const [resourceFiltersOff, setResourceFiltersOff] = useState(
        store.get('resourceFiltersOff') || {
            roles: [],
            franchiseGroups: [],
            projectTypes: [],
        }
    );
    const [displayFranchises, setDisplayFranchises] = useState([]);
    const [userProjects, setUserProjects] = useState({});
    const [chartUsers, setChartUsers] = useState([]);
    const [chartMeta, setChartMeta] = useState({
        largest: 0,
        colors: {},
    });
    const [menuOpen, setMenuOpen] = useState({
        roles: false,
        franchiseGroups: false,
        projectTypes: false,
    });

    const fetchResourcePlannerAppData = async () => {
        const response = await getResourcePlanAppData();
        if (response) {
            setResourcePlannerAppData(response);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const fetchProjectsResources = async () => {
        setTimeout(() => setFetchingProjectsResources(true), 1);
        const response = await getProjectsAndResources({ year, quarter });
        if (response) {
            response.franchiseGroups.push('- No Franchise -');
            response.projectTypes.push('- No Type -');
            setProjectsResources(response);
        }
        setTimeout(() => setFetchingProjectsResources(false), 1);
    };

    useEffect(() => {
        if (!resourcePlannerAppData) {
            fetchResourcePlannerAppData();
            const yearQuarter = store.get('resourceYearQuarter');
            if (
                yearQuarter &&
                (!year || !quarter) &&
                (yearQuarter.year !== year || yearQuarter.quarter !== quarter)
            ) {
                history.push(
                    `/resources${yearQuarter.year ? `/${yearQuarter.year}` : ''}${
                        yearQuarter.quarter ? `/${yearQuarter.quarter}` : ''
                    }`
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourcePlannerAppData]);

    useEffect(() => {
        if (year && quarter) {
            fetchProjectsResources();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, quarter]);

    useEffect(() => {
        if (projectsResources) {
            const { projects } = projectsResources;
            const tmpUserProjects = {};
            const tmpDisplayFranchises = {};

            projects.map((project) => {
                // console.log(project);
                const { franchiseGroup, designSets, assets } = project;
                if (!tmpDisplayFranchises[franchiseGroup || '- No Franchise -']) {
                    tmpDisplayFranchises[franchiseGroup || '- No Franchise -'] = {
                        name: franchiseGroup,
                        designSets: 0,
                        assets: 0,
                        projects: [],
                    };
                }
                tmpDisplayFranchises[franchiseGroup || '- No Franchise -'].projects.push(project);
                tmpDisplayFranchises[franchiseGroup || '- No Franchise -'].designSets += designSets;
                tmpDisplayFranchises[franchiseGroup || '- No Franchise -'].assets += assets;

                project.roles.map((role) => {
                    role.yes.map((user) => {
                        if (!tmpUserProjects[user.userID]) {
                            tmpUserProjects[user.userID] = [];
                        }
                        tmpUserProjects[user.userID].push(project);

                        return null;
                    });

                    return null;
                });

                return null;
            });
            setDisplayFranchises(tmpDisplayFranchises);
            setUserProjects(tmpUserProjects);
        }
    }, [projectsResources]);

    useEffect(() => {
        const { projects } = projectsResources || { projects: [] };
        const filteredUsers = {};
        const tmpChartMeta = {
            largest: 0,
            colors: {},
        };
        let colorCount = 0;

        // check projects
        projects.map((project) => {
            // filter projects
            if (
                resourceFiltersOff.franchiseGroups.indexOf(
                    project.franchiseGroup || '- No Franchise -'
                ) === -1
            ) {
                // filter types
                if (
                    resourceFiltersOff.projectTypes.indexOf(
                        project.projectType || '- No Type -'
                    ) === -1
                ) {
                    project.roles.map((role) => {
                        if (resourceFiltersOff.roles.indexOf(role.name) === -1) {
                            role.yes.map((user) => {
                                if (
                                    !tmpChartMeta.colors[
                                        project.franchiseGroup || '- No Franchise -'
                                    ]
                                ) {
                                    tmpChartMeta.colors[
                                        project.franchiseGroup || '- No Franchise -'
                                    ] = colorCount++;
                                    if (colorCount === 11) {
                                        colorCount = 0;
                                    }
                                }
                                if (!filteredUsers[user.userID]) {
                                    filteredUsers[user.userID] = {
                                        total: 0,
                                        franchises: {},
                                        user,
                                    };
                                }
                                if (
                                    !filteredUsers[user.userID].franchises[
                                        project.franchiseGroup || '- No Franchise -'
                                    ]
                                ) {
                                    filteredUsers[user.userID].franchises[
                                        project.franchiseGroup || '- No Franchise -'
                                    ] = 0;
                                }
                                filteredUsers[user.userID].franchises[
                                    project.franchiseGroup || '- No Franchise -'
                                ] += 1;
                                filteredUsers[user.userID].total += 1;

                                return null;
                            });
                        }

                        return null;
                    });
                }
            }

            return null;
        });

        const filteredArray = Object.keys(filteredUsers).map((UserID) => filteredUsers[UserID]);
        filteredArray.sort((a, b) => a.total - b.total).reverse();

        setChartUsers(filteredArray);
        if (filteredArray.length) {
            setChartMeta({
                ...tmpChartMeta,
                largest: filteredArray[0].total,
            });
        }

        // console.log(filteredProjects);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProjects, resourceFiltersOff, projectsResources]);

    const getFilterDefaultValues = (filterType) => {
        const returnArray = [];
        projectsResources[filterType].map((choice) => {
            if (resourceFiltersOff[filterType].indexOf(choice) === -1) {
                returnArray.push({
                    value: choice,
                    label: choice,
                });
            }

            return null;
        });

        return returnArray;
    };

    // eslint-disable-next-line react/prop-types
    const FinalizeButton = ({ project }) => {
        // eslint-disable-next-line react/prop-types
        const { canFinalize, isFinalized, id } = project;
        const [showConfirm, setShowConfirm] = useState();
        const [hovered, setHovered] = useState();
        const [saving, setSaving] = useState();
        const clicked = () => {
            setSaving(true);
            const doFinalize = async () => {
                await finalizeProject(id);
                await setProjectsResources({
                    ...projectsResources,
                    projects: projectsResources.projects.map((innerProject) =>
                        innerProject.id === id
                            ? {
                                  ...innerProject,
                                  isFinalized: true,
                              }
                            : innerProject
                    ),
                });
                setSaving(false);
            };
            doFinalize();
        };

        return (
            <div
                className={`ml-3 finalize-wrapper ${!canFinalize ? 'disabled' : ''}${
                    isFinalized ? 'finalized' : ''
                }${saving ? ' thinking' : ''}`}
                style={{ width: '120px', height: '38px' }}
                onMouseEnter={() => (isFinalized ? setHovered(true) : {})}
                onMouseLeave={() => {
                    if (isFinalized) {
                        setHovered(false);
                    }
                    setShowConfirm(false);
                }}
            >
                {!saving && !showConfirm ? (
                    <>
                        {!hovered ? (
                            <Button
                                color={isFinalized ? 'primary' : undefined}
                                outline
                                className="w-100 finalize"
                                disabled={!canFinalize}
                                onClick={() => setShowConfirm(true)}
                            >
                                Finalize
                                {isFinalized ? 'd' : ''}
                            </Button>
                        ) : (
                            <Button
                                className="w-100 regnerate"
                                disabled={!canFinalize}
                                onClick={() => setShowConfirm(true)}
                            >
                                Regenerate?
                            </Button>
                        )}
                    </>
                ) : !saving ? (
                    <Button
                        color="primary"
                        disabled={!canFinalize}
                        className="w-100 confirm"
                        onClick={clicked}
                    >
                        Confirm
                    </Button>
                ) : null}
            </div>
        );
    };

    // eslint-disable-next-line react/prop-types
    const User = ({ user, project, role, yes, hideLock, clickInfo }) => {
        // eslint-disable-next-line react/prop-types
        const { userID, name, locked } = user;
        const [thisUserProjects, setThisUserProjects] = useState();
        const [popoverOpen, setShowPopoverOpen] = useState();

        const checkCloseInfo = (event) => {
            setShowPopoverOpen(false);
        };

        useEffect(() => {
            // initiate the event handler
            window.addEventListener('click', checkCloseInfo);

            // this will clean up the event every time the component is re-rendered
            return function cleanup() {
                window.removeEventListener('click', checkCloseInfo);
            };
        });

        const fetchUserProjects = async () => {
            const result = await getUserProjects(userID);
            if (result) {
                setThisUserProjects(result.projects);
            }
        };

        useEffect(() => {
            if (popoverOpen && !thisUserProjects) {
                fetchUserProjects();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [popoverOpen]);

        return (
            <div
                id={clickInfo ? `profile-${user.userID}` : undefined}
                className="user"
                style={{ cursor: clickInfo ? 'pointer' : 'inherit' }}
                onClick={() =>
                    clickInfo ? setTimeout(() => setShowPopoverOpen(!popoverOpen), 200) : {}
                }
            >
                <div className="d-flex align-items-center">
                    <div
                        className="d-flex align-items-start justify-content-start profile"
                        style={{
                            backgroundImage: `url(${appData.workfrontUrl}/internal/user/avatar?ID=${userID})`,
                        }}
                    >
                        {!hideLock ? (
                            <FontAwesomeIcon
                                icon={['fa', 'lock']}
                                className={`lock${locked ? ' locked' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    const newRoles = {
                                        yes: yes
                                            ? role.yes.map((roleUser) =>
                                                  roleUser.userID === userID
                                                      ? { ...roleUser, locked: !locked }
                                                      : roleUser
                                              )
                                            : role.yes,
                                        no: !yes
                                            ? role.no.map((roleUser) =>
                                                  roleUser.userID === userID
                                                      ? { ...roleUser, locked: !locked }
                                                      : roleUser
                                              )
                                            : role.no,
                                    };
                                    setProjectsResources({
                                        ...projectsResources,
                                        projects: projectsResources.projects.map((innerProject) =>
                                            innerProject.id === project.id
                                                ? {
                                                      ...innerProject,
                                                      roles: innerProject.roles.map((innerRole) =>
                                                          innerRole.id === role.id
                                                              ? {
                                                                    ...innerRole,
                                                                    ...newRoles,
                                                                }
                                                              : innerRole
                                                      ),
                                                  }
                                                : innerProject
                                        ),
                                    });
                                    setTimeout(() => {
                                        updateRoleResources(project.id, role.id, {
                                            id: role.id,
                                            name: role.name,
                                            ...newRoles,
                                        });
                                    }, 100);
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="moveable">
                        <div className="name">{name}</div>
                        <div className="projects">
                            {userProjects && userProjects[userID] ? userProjects[userID].length : 0}{' '}
                            project
                            {(userProjects &&
                                userProjects[userID] &&
                                userProjects[userID].length > 1) ||
                            !userProjects[userID] ||
                            !userProjects
                                ? 's'
                                : ''}
                        </div>
                        {!hideLock && !locked ? (
                            <FontAwesomeIcon
                                icon={['far', 'times-circle']}
                                className="remove-user"
                                onClick={() => {
                                    const newYes = role.yes.filter(
                                        (user) => user.userID !== userID
                                    );

                                    setProjectsResources({
                                        ...projectsResources,
                                        projects: projectsResources.projects.map((innerProject) =>
                                            innerProject.id === project.id
                                                ? {
                                                      ...innerProject,
                                                      roles: innerProject.roles.map((innerRole) =>
                                                          innerRole.id === role.id
                                                              ? {
                                                                    ...innerRole,
                                                                    yes: newYes,
                                                                }
                                                              : innerRole
                                                      ),
                                                  }
                                                : innerProject
                                        ),
                                    });

                                    setTimeout(() => {
                                        updateRoleResources(project.id, role.id, {
                                            id: role.id,
                                            name: role.name,
                                            no: role.no,
                                            yes: newYes,
                                        });
                                    }, 100);
                                }}
                            />
                        ) : null}
                    </div>
                    {clickInfo ? (
                        <Popover
                            container={container.current}
                            placement="right"
                            isOpen={popoverOpen}
                            target={`profile-${user.userID}`}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <PopoverBody>
                                {!thisUserProjects ? (
                                    <div
                                        className="thinking mx-auto"
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                    />
                                ) : (
                                    <>
                                        <h5>
                                            {thisUserProjects.length +
                                                userProjects[user.userID].length}{' '}
                                            Active Projects
                                        </h5>
                                        {thisUserProjects
                                            .concat(userProjects[user.userID])
                                            .map((project) => (
                                                <p key={`project:${project.id}`} className="mb-2">
                                                    <a
                                                        href={`${appData.workfrontUrl}/project/${project.id}`}
                                                        target="new"
                                                    >
                                                        {project.name}
                                                    </a>
                                                </p>
                                            ))}
                                    </>
                                )}
                                <FontAwesomeIcon
                                    icon={['far', 'times-circle']}
                                    className="close-info"
                                    onClick={() => setShowPopoverOpen(!popoverOpen)}
                                />
                            </PopoverBody>
                        </Popover>
                    ) : null}
                </div>
            </div>
        );
    };

    const RolesColumn = ({ role, project }) => {
        const columnRef = useRef();

        return (
            <div key={role.id} className="roles-column" ref={columnRef}>
                <h5>{role.name}</h5>
                <div className="roles-yes">
                    {role.yes.map((user) =>
                        user.locked ? (
                            <User
                                key={`${project.id}:${role.id}:${user.userID}`}
                                user={user}
                                project={project}
                                role={role}
                                yes
                            />
                        ) : null
                    )}
                    {role.yes.map((user) =>
                        !user.locked ? (
                            <User
                                key={`${project.id}:${role.id}:${user.userID}`}
                                user={user}
                                project={project}
                                role={role}
                                yes
                            />
                        ) : null
                    )}
                </div>
                <AsyncSelect
                    controlShouldRenderValue={false}
                    placeholder="Add user..."
                    value={undefined}
                    loadOptions={async (inputValue, callback) => {
                        const results = await searchUsers(inputValue, role.id);

                        const existing = role.yes.map((user) => user.userID);

                        const filteredResults = results.filter(
                            (resultUser) => existing.indexOf(resultUser.id) === -1
                        );

                        callback(
                            filteredResults.map((result) => ({
                                value: result.id,
                                label: result.name,
                            }))
                        );
                    }}
                    classNamePrefix="react-select"
                    className="add-user d-flex align-items-center"
                    onChange={async (choice) => {
                        updateRoleResources(project.id, role.id, {
                            id: role.id,
                            name: role.name,
                            no: role.no,
                            yes: [
                                ...role.yes,
                                {
                                    userID: choice.value,
                                    name: choice.label,
                                    locked: false,
                                },
                            ],
                        });
                        setProjectsResources({
                            ...projectsResources,
                            projects: projectsResources.projects.map((innerProject) =>
                                innerProject.id === project.id
                                    ? {
                                          ...innerProject,
                                          roles: innerProject.roles.map((innerRole) =>
                                              innerRole.id === role.id
                                                  ? {
                                                        ...innerRole,
                                                        yes: [
                                                            ...innerRole.yes,
                                                            {
                                                                userID: choice.value,
                                                                name: choice.label,
                                                                locked: false,
                                                            },
                                                        ],
                                                    }
                                                  : innerRole
                                          ),
                                      }
                                    : innerProject
                            ),
                        });
                    }}
                />
            </div>
        );
    };

    const SelectFilter = ({ type }) => {
        const saveChoices = (choices) => {
            const selected = choices.map(({ value }) => value);
            const newToggledOff = Object.assign(resourceFiltersOff, {
                [type]: projectsResources[type].filter(
                    (internalVal) => selected.indexOf(internalVal) === -1
                ),
            });
            setResourceFiltersOff((preValue) => ({
                ...preValue,
                ...newToggledOff,
            }));
            store.set('resourceFiltersOff', newToggledOff);
        };

        return (
            <Select
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
                blurInputOnSelect={false}
                onMenuOpen={() => {
                    const tmpOpen = { ...menuOpen, [type]: !menuOpen[type] };
                    setMenuOpen(tmpOpen);
                }}
                onMenuClose={() => {
                    const tmpOpen = { ...menuOpen, [type]: false };
                    setMenuOpen(tmpOpen);
                }}
                menuIsOpen={menuOpen[type]}
                components={{
                    ValueContainer: ({ children, ...internalProps }) => {
                        const selected = internalProps.getValue().length;

                        return (
                            <components.ValueContainer {...internalProps}>
                                {selected === projectsResources[type].length ? 'All ' : ''}
                                {type === 'roles'
                                    ? 'Roles'
                                    : type === 'franchiseGroups'
                                    ? 'Franchise Groups'
                                    : 'Project Types'}
                                {selected < projectsResources[type].length ? ` (${selected})` : ''}
                                {children[1]}
                            </components.ValueContainer>
                        );
                    },
                    MenuList: (props) => (
                        <components.MenuList {...props}>
                            <div>
                                <Button
                                    size="sm"
                                    color="link"
                                    className="mr-3"
                                    onClick={() =>
                                        saveChoices(
                                            projectsResources[type].map((val) => ({
                                                value: val,
                                                label: val,
                                            }))
                                        )
                                    }
                                >
                                    Select All
                                </Button>
                                <Button size="sm" color="link" onClick={() => saveChoices([])}>
                                    Select None
                                </Button>
                            </div>
                            {props.children}
                        </components.MenuList>
                    ),
                    Option: (props) => (
                        <components.Option {...props} style={{ cursor: 'pointer' }}>
                            {props.isSelected ? (
                                <FontAwesomeIcon
                                    icon={['fas', 'check']}
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '.5rem',
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '.5rem',
                                        display: 'inline-block',
                                    }}
                                />
                            )}
                            {props.children}
                        </components.Option>
                    ),
                }}
                isClearable={false}
                hideSelectedOptions={false}
                classNamePrefix="react-select"
                className="select-filter"
                isMulti
                defaultValue={getFilterDefaultValues(type)}
                options={projectsResources[type].map((val) => ({
                    value: val,
                    label: val,
                }))}
                onChange={saveChoices}
            />
        );
    };

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'resources',
                }}
                title="Resources Planner"
            />
            <Loading container loading={loading} error={error} className="px-0">
                <StyledWrapper ref={container}>
                    {resourcePlannerAppData ? (
                        <Row className="d-flex align-items-center">
                            <Col md="9" className="d-flex align-items-center">
                                <label className="mr-3 mb-0 text-nowrap">
                                    {resourcePlannerAppData.searchFields[0].name}:
                                </label>
                                <Select
                                    className="nav-select"
                                    placeholder="Project Year"
                                    classNamePrefix="react-select"
                                    isCLearable={false}
                                    options={resourcePlannerAppData.searchFields[0].options.filter(
                                        (option) => !option.isHidden
                                    )}
                                    onChange={({ value }) => {
                                        history.push(
                                            `/resources/${value}${quarter ? `/${quarter}` : ''}`
                                        );
                                        store.set('resourceYearQuarter', {
                                            year: value,
                                            quarter,
                                        });
                                    }}
                                    value={
                                        year
                                            ? {
                                                  value: year,
                                                  label: year,
                                              }
                                            : undefined
                                    }
                                />
                                <label className="ml-5 mr-3 mb-0 text-nowrap">
                                    {resourcePlannerAppData.searchFields[1].name}:
                                </label>
                                <Select
                                    className="nav-select"
                                    disabled={!year}
                                    placeholder="Project Quarter"
                                    classNamePrefix="react-select"
                                    isCLearable={false}
                                    options={resourcePlannerAppData.searchFields[1].options.filter(
                                        (option) => !option.isHidden
                                    )}
                                    onChange={({ value }) => {
                                        history.push(`/resources/${year}/${value}`);
                                        store.set('resourceYearQuarter', {
                                            year,
                                            quarter: value,
                                        });
                                    }}
                                    value={
                                        quarter
                                            ? resourcePlannerAppData.searchFields[1].options.find(
                                                  (option) => quarter === option.value
                                              )
                                            : undefined
                                    }
                                />
                            </Col>
                            <Col md="3" className="ml-auto text-right">
                                {year && quarter && !fetchingProjectsResources ? (
                                    <FontAwesomeIcon
                                        icon={['fa', 'redo']}
                                        style={{
                                            cursor: 'pointer',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                        onClick={() => fetchProjectsResources()}
                                    />
                                ) : year && quarter && fetchingProjectsResources ? (
                                    <div
                                        className="thinking ml-auto"
                                        style={{
                                            cursor: 'pointer',
                                            width: '30px',
                                            height: '30px',
                                        }}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    ) : null}
                    {year && quarter ? (
                        <Loading loading={fetchingProjectsResources}>
                            {projectsResources ? (
                                <>
                                    <hr />
                                    <Row>
                                        <Col md="12">
                                            <h2>Assigned Resources</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="mt-3 d-flex align-items-center">
                                            <label className="mb-0 text-nowrap">Filters:</label>
                                            <SelectFilter type="roles" />
                                            <SelectFilter type="franchiseGroups" />
                                            <SelectFilter type="projectTypes" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="mt-3">
                                            {chartUsers.length > 0 ? (
                                                <div className="users-chart">
                                                    {chartUsers.map((user) => (
                                                        <div
                                                            key={`userchart:${user.user.userID}`}
                                                            className="user-chart d-flex flex-row align-items-center"
                                                        >
                                                            <User
                                                                user={user.user}
                                                                hideLock
                                                                clickInfo
                                                            />
                                                            <div className="bar-wrapper w-100 ml-3 d-flex">
                                                                {Object.keys(user.franchises).map(
                                                                    (franchiseName) => (
                                                                        <div
                                                                            key={`${user.user.userID}:${franchiseName}`}
                                                                            className="franchise px-2 py-1"
                                                                            style={{
                                                                                width: `${
                                                                                    (user
                                                                                        .franchises[
                                                                                        franchiseName
                                                                                    ] /
                                                                                        chartMeta.largest) *
                                                                                    100
                                                                                }%`,
                                                                                backgroundColor: `rgba(0, 0, 0, ${
                                                                                    1 -
                                                                                    chartMeta
                                                                                        .colors[
                                                                                        franchiseName
                                                                                    ] *
                                                                                        0.1
                                                                                })`,
                                                                                color:
                                                                                    chartMeta
                                                                                        .colors[
                                                                                        franchiseName
                                                                                    ] < 4
                                                                                        ? 'white'
                                                                                        : 'black',
                                                                            }}
                                                                        >
                                                                            {franchiseName}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <h3>No users</h3>
                                            )}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="d-flex align-items-center px-2">
                                        <Col md="6">
                                            <h2 className="mb-0">
                                                {Object.keys(displayFranchises).reduce(
                                                    (total, franchiseName) =>
                                                        total +
                                                        displayFranchises[franchiseName].projects
                                                            .length,
                                                    0
                                                )}{' '}
                                                Projects
                                            </h2>
                                        </Col>
                                        <Col md="6" className="justify-content-end d-flex">
                                            <h4 className="mb-0 label-totals">
                                                {Object.keys(displayFranchises).reduce(
                                                    (total, franchiseName) =>
                                                        total +
                                                        displayFranchises[franchiseName].designSets,
                                                    0
                                                )}{' '}
                                                Design Sets
                                            </h4>
                                            <h4 className="mb-0 label-totals">
                                                {Object.keys(displayFranchises).reduce(
                                                    (total, franchiseName) =>
                                                        total +
                                                        displayFranchises[franchiseName].assets,
                                                    0
                                                )}{' '}
                                                Assets
                                            </h4>
                                        </Col>
                                    </Row>
                                    {Object.keys(displayFranchises)
                                        .sort()
                                        .map((franchiseKey) => (
                                            <Row key={`project:${franchiseKey}`}>
                                                <Col>
                                                    <div
                                                        className="franchise-toggle mt-3 p-2 d-flex justify-content-between"
                                                        onClick={() => {
                                                            const tmpFranchisesToggled =
                                                                franchisesToggled.indexOf(
                                                                    franchiseKey
                                                                ) > -1
                                                                    ? franchisesToggled.filter(
                                                                          (franchiseName) =>
                                                                              franchiseName !==
                                                                              franchiseKey
                                                                      )
                                                                    : [
                                                                          ...franchisesToggled,
                                                                          franchiseKey,
                                                                      ];
                                                            setFranchisesToggled(
                                                                tmpFranchisesToggled
                                                            );
                                                            store.set(
                                                                'franchisesToggled',
                                                                tmpFranchisesToggled
                                                            );
                                                        }}
                                                    >
                                                        <h4 className="mb-0">
                                                            {franchiseKey}
                                                            <FontAwesomeIcon
                                                                className="ml-3"
                                                                icon={[
                                                                    'fa',
                                                                    franchisesToggled.indexOf(
                                                                        franchiseKey
                                                                    ) > -1
                                                                        ? 'chevron-down'
                                                                        : 'chevron-right',
                                                                ]}
                                                            />
                                                        </h4>
                                                        <div className="justify-content-end d-flex">
                                                            <h4 className="mb-0 label-totals">
                                                                {
                                                                    displayFranchises[franchiseKey]
                                                                        .designSets
                                                                }{' '}
                                                                Design Sets
                                                            </h4>
                                                            <h4 className="mb-0 label-totals">
                                                                {
                                                                    displayFranchises[franchiseKey]
                                                                        .assets
                                                                }{' '}
                                                                Assets
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <Collapse
                                                        isOpen={
                                                            franchisesToggled.indexOf(
                                                                franchiseKey
                                                            ) > -1
                                                        }
                                                        className="mt-3"
                                                    >
                                                        {displayFranchises[
                                                            franchiseKey
                                                        ].projects.map((project) => (
                                                            <div
                                                                className="project"
                                                                key={`project:${project.name}`}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex">
                                                                        <h5 className="mb-0">
                                                                            <em>
                                                                                <a
                                                                                    href={`${appData.workfrontUrl}/project/${project.id}`}
                                                                                    target="new"
                                                                                >
                                                                                    {project.name}
                                                                                </a>
                                                                            </em>
                                                                        </h5>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            Type{' '}
                                                                            <strong>
                                                                                <em>
                                                                                    {
                                                                                        project.projectType
                                                                                    }
                                                                                </em>
                                                                            </strong>
                                                                        </div>
                                                                        <div className="ml-3">
                                                                            Design Sets{' '}
                                                                            <strong>
                                                                                <em>
                                                                                    {
                                                                                        project.designSets
                                                                                    }
                                                                                </em>
                                                                            </strong>
                                                                        </div>
                                                                        <div className="ml-3">
                                                                            Project Owner{' '}
                                                                            <strong>
                                                                                <em>
                                                                                    {
                                                                                        project.projectOwner
                                                                                    }
                                                                                </em>
                                                                            </strong>
                                                                        </div>
                                                                        <FinalizeButton
                                                                            project={project}
                                                                            projectsResources={
                                                                                projectsResources
                                                                            }
                                                                        />
                                                                        <FontAwesomeIcon
                                                                            className="ml-3"
                                                                            style={{
                                                                                width: '45px',
                                                                                height: '45px',
                                                                                cursor: project.canApprove
                                                                                    ? 'pointer'
                                                                                    : undefined,
                                                                                color: project.isApproved
                                                                                    ? '#6BB942'
                                                                                    : undefined,
                                                                            }}
                                                                            icon={[
                                                                                project.isApproved
                                                                                    ? 'fas'
                                                                                    : 'far',
                                                                                'check-square',
                                                                            ]}
                                                                            onClick={() => {
                                                                                if (
                                                                                    project.canApprove
                                                                                ) {
                                                                                    markProjectReady(
                                                                                        project.id,
                                                                                        !project.isApproved
                                                                                    );
                                                                                    setProjectsResources(
                                                                                        {
                                                                                            ...projectsResources,
                                                                                            projects: projectsResources.projects.map(
                                                                                                (
                                                                                                    innerProject
                                                                                                ) =>
                                                                                                    innerProject.id ===
                                                                                                    project.id
                                                                                                        ? {
                                                                                                              ...innerProject,
                                                                                                              isApproved: !innerProject.isApproved,
                                                                                                          }
                                                                                                        : innerProject
                                                                                            ),
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="roles d-flex flex-row justify-content-between">
                                                                    {project.roles.map((role) => (
                                                                        <RolesColumn
                                                                            key={`role:${role.id}`}
                                                                            role={role}
                                                                            project={project}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Collapse>
                                                </Col>
                                            </Row>
                                        ))}
                                </>
                            ) : null}
                        </Loading>
                    ) : null}
                </StyledWrapper>
            </Loading>
        </>
    );
};

export default Resources;
