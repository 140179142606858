import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withCookies } from 'react-cookie';
import queryString from 'query-string';
import store from 'store2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import RequestView from './pages/requestView';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFolder,
    faFile,
    faDownload,
    faExternalLinkAlt,
    faChevronRight,
    faChevronDown,
    faExclamationTriangle,
    faCheckCircle,
    faEye,
    faCog,
    faRedo,
    faLock,
    faCheckSquare as fasCheckSquare,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { faSquare, faCheckSquare, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { ScrollTop } from './utils';
import { Loading, Home, Request, Thanks, AssetMap, Resources } from './pages';
import Project from './pages/project';
import { Header } from './layout';
import { AppContext } from './context';
import { getToken, getAppData, getQueueDefinitions } from './utils/api';

toast.configure();
library.add(
    faFolder,
    faFile,
    faDownload,
    faExternalLinkAlt,
    faChevronRight,
    faChevronDown,
    faExclamationTriangle,
    faCheckCircle,
    faEye,
    faCog,
    faSquare,
    faCheckSquare,
    faRedo,
    faLock,
    fasCheckSquare,
    faCheck,
    faTimesCircle
);

function App(props) {
    const { location } = props;

    const parseQS = queryString.parse(location.search);
    if (parseQS['X-WF-SESSION-ID']) {
        store.session('X-WF-SESSION-ID', parseQS['X-WF-SESSION-ID']);
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(parseQS.ERROR || false);
    const [authToken, setAuthToken] = useState(
        parseQS['X-WF-SESSION-ID'] || store.session('X-WF-SESSION-ID') || null
    );
    const [appData, setAppData] = useState(null);
    const [queueDefinitions, setQueueDefinitions] = useState([]);
    const [definitionsLoading, setDefinitionsLoading] = useState(true);
    const [definitionsError, setDefinitionsError] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            const token = await getToken();
            setAuthToken(token);
        };
        if (process.env.NODE_ENV !== 'production' && !authToken) {
            fetchToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchAppData = async () => {
            const data = await getAppData();
            setLoading(false);
            if (!data) {
                setError(true);
            } else {
                setAppData(data);
            }
        };
        if (authToken) {
            fetchAppData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    useEffect(() => {
        const fetchQueueDefinitions = async () => {
            const definitions = await getQueueDefinitions();
            setDefinitionsLoading(false);
            if (definitions && definitions.length) {
                setQueueDefinitions(definitions);
            } else {
                setDefinitionsError(true);
            }
        };
        fetchQueueDefinitions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appData]);

    // hearbeat to keep token live
    // useEffect(() => {
    //     const fetchAppDataInterval = window.setInterval(async () => {
    //         const data = await getAppData();
    //         if (data) {
    //             // setting this will cause a re-render, be careful
    //             // setAppData(data);
    //         }
    //     }, 1000 * 60 * 5);

    //     return () => {
    //         window.clearInterVal(fetchAppDataInterval);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <ScrollTop>
            <Helmet
                htmlAttributes={{
                    'data-page': 'homepage',
                    lang: 'en',
                }}
                defaultTitle="DCP"
                titleTemplate="%s - DCP"
            >
                <meta charSet="utf-8" />
            </Helmet>
            <AppContext.Provider value={appData}>
                <Loading loading={loading} error={error} container fluid id="scrollable-area">
                    <Route
                        render={(routeProps) =>
                            !routeProps.location.pathname.indexOf('/assetmap') === -1 ? (
                                <Header {...routeProps} />
                            ) : null
                        }
                    />
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(routeProps) => (
                                <Home
                                    {...routeProps}
                                    queueDefinitions={queueDefinitions}
                                    definitionsLoading={definitionsLoading}
                                    definitionsError={definitionsError}
                                />
                            )}
                        />
                        <Route
                            path={['/requestView/:requestId/:activeTab?']}
                            render={(routeProps) => <RequestView {...routeProps} />}
                        />
                        <Route
                            path={['/request/:projectId/:topicId?']}
                            render={(routeProps) => (
                                <Request
                                    {...routeProps}
                                    queueDefinitions={queueDefinitions}
                                    definitionsLoading={definitionsLoading}
                                    definitionsError={definitionsError}
                                />
                            )}
                        />
                        <Route
                            path={['/project/:projectId/:activeTab?']}
                            render={(routeProps) => <Project {...routeProps} />}
                        />
                        <Route
                            path={['/assetmap/:assetMapId']}
                            render={(routeProps) => <AssetMap {...routeProps} />}
                        />
                        <Route
                            path={['/resources/:year?/:quarter?']}
                            render={(routeProps) => <Resources {...routeProps} />}
                        />
                        <Route
                            exact
                            path="/thanks"
                            render={(routeProps) => <Thanks {...routeProps} />}
                        />
                        <Route
                            exact
                            path="/loading"
                            render={(routeProps) => (
                                <Loading {...routeProps} loading container fluid />
                            )}
                        />
                        <Route
                            exact
                            path="/error"
                            render={(routeProps) => (
                                <Loading {...routeProps} loading={false} error container fluid />
                            )}
                        />
                        <Route
                            render={(routeProps) => (
                                <Loading
                                    {...routeProps}
                                    loading={false}
                                    pageNotFound
                                    container
                                    fluid
                                />
                            )}
                        />
                    </Switch>
                </Loading>
            </AppContext.Provider>
        </ScrollTop>
    );
}

App.propTypes = {
    location: PropTypes.objectOf(PropTypes.string).isRequired,
    cookies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

export default withCookies(withRouter(App));
