import React, { useState } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import ThinkingButton from '../thinkingButton';

const GenerateDocumentsButton = (props) => {
    const { generateDocuments, submitForm } = props;
    const [disabled, setDisabled] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Generate Documents');
    // eslint-disable-next-line react/destructuring-assignment
    const [messageText, setMessageText] = useState(props.messageText);
    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <ThinkingButton
                thinking={submitting}
                type="button"
                outline
                color="primary"
                disabled={disabled}
                onClick={async () => {
                    setDisabled(true);
                    setSubmitting(true);
                    setButtonLabel('Generating documents...');
                    await submitForm();
                    setMessageText('This can take over 1 minute to run.  You do not need to wait.');
                    generateDocuments().then((res) => {
                        setDisabled(false);
                        setSubmitting(false);
                        setButtonLabel('Generate Documents');
                        if (res) {
                            setMessageText(res);
                        } else {
                            setMessageText('No response');
                        }
                    });
                }}
            >
                {buttonLabel}
            </ThinkingButton>
            {messageText ? (
                <span className="ml-4 mt-2 mb-0">
                    <Label> {messageText}</Label>
                </span>
            ) : null}
        </>
    );
};

GenerateDocumentsButton.propTypes = {
    submitForm: PropTypes.func,
    generateDocuments: PropTypes.func,
    messageText: PropTypes.string,
};
GenerateDocumentsButton.defaultProps = {
    submitForm: () => {},
    generateDocuments: () => {},
    messageText: '',
};
export default GenerateDocumentsButton;
