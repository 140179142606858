/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import { useParams, useLocation } from 'react-router';
import store from 'store2';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import domtoimage from 'dom-to-image';
import { Loading } from '.';
import { getAssetMap, saveAssetMap, updateAssetDescription } from '../utils/api';

const apiUrl = process.env.REACT_APP_API_URL || '/WEB_API/1.0.0';

const assetWidth = 96;
const assetHeight = Math.ceil(assetWidth * 0.822);
const assetSpacing = 8;
const baseFontSize = 16;

const maxColumnSize = 11;

const StyledContainer = styled.div`
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: ${baseFontSize}px;
    width: 1200px;

    #assetmap-loading {
        padding: 0;
        > .row {
            margin: 0;
            > .col {
                padding: 0;
            }
        }
    }

    h1,
    h2,
    h3 {
        text-transform: uppercase;
        margin: 0;
        font-weight: bold;
    }
    h1 {
        font-size: 1.875em;
    }
    h2 {
        font-size: 1.3125em;
    }
    h3 {
        font-size: 1em;
    }

    .rounded-circle {
        width: 1.6875em;
        height: 1.6875em;
        background-color: #f5f5f5;
        border: solid 0.125em #4090ee;
        &.active {
            background-color: #4090ee;
        }
        & + .rounded-circle {
            margin-left: 1em;
        }
    }

    .legend {
        width: 7.8125em;
        > div {
            font-size: 0.75em;
            color: black;
            border: solid 1px #cbcbcb;
            border-radius: 0.125em;
            padding: 0.625em 1em;
        }
        h3 {
            font-size: 1em;
            margin-bottom: 0.625em;
            text-transform: inherit;
            whitespace: nowrap;
        }
        .status {
            margin-bottom: 0.25em;
            .color {
                margin: 0 0.25em;
                min-width: 1em;
                min-height: 1em;
            }
        }
    }

    figure {
        text-align: center;
        width: ${assetWidth}px;
        margin: 0 ${assetSpacing / 2}px;
        position: relative;
        figcaption {
            color: black;
            font-size: 0.5em;
            font-weight: bold;
            margin: 0.25em 0 1em 0;
            &.Review {
                color: #4090ee;
            }
            &.Rejected {
                color: #ea2a2a;
            }
            &.Approved {
                color: #6bb942;
            }
        }
        .artwork,
        .placeholder {
            background-color: #f5f5f5;
            border-radius: 0.125em;
            width: ${assetWidth}px;
            height: ${assetHeight}px;
        }
        .artwork {
            position: relative;
            .art {
                max-width: 96px;
                max-height: 79px;
                margin: auto;
            }
            .icon-success {
                position: absolute;
                right: 0.3125em;
                bottom: 0.3125em;
                width: 0.6875em;
                height: 0.6875em;
            }
            .versions {
                display: none;
                overflow: auto;
                .version {
                    text-align: left;
                    background-color: #f5f5f5;
                    border-radius: 0.5em;
                    padding: 0.25rem;
                    font-size: 0.75em;
                    h5 {
                        font-size: 0.875em;
                    }
                    & + .version {
                        margin-top: 0.5em;
                    }
                    .meta,
                    .actions {
                        font-style: italic;
                    }
                    .ver {
                        font-style: normal;
                    }
                    svg {
                        color: #6c757d;
                    }
                }
            }
            &.viewing,
            &:hover {
                background-color: transparent;
                .art,
                .icon-success {
                    display: none;
                }
                .versions {
                    display: flex;
                }
            }
        }
        .placeholder {
            padding: 0.25em;
            textarea {
                font-size: 0.5625em;
                justify-content: center;
                display: flex;
                align-items: center;
                text-align: center;
                background-color: transparent;
                border: solid 1px transparent;
            }
            .actions {
                display: none;
                padding-top: 0.25em;
                svg {
                    cursor: pointer;
                }
            }
            &.editing,
            &:hover {
                textarea {
                    background-color: #fff;
                    border: solid 1px #6c757d;
                }
                .actions {
                    display: flex;
                }
            }
        }
        &.banner {
            width: ${Math.ceil(assetWidth * 4.288)}px;
            .placeholder,
            img {
                width: ${Math.ceil(assetWidth * 4.288)}px;
            }
        }
    }

    .asset-group {
        flex-grow: 0;
        margin-right: 1.5rem;
        padding-bottom: 1.5rem;
        &.asset-group-header {
            padding-bottom: 0;
        }
        .asset-title {
            position: relative;
            h3 {
            }
            .dropdown {
                display: none;
                flex: 1;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                > button {
                    line-height: 1;
                    flex: 1;
                    padding: 1.5px 0 !important;
                    background-color: transparent;
                    padding: 0;
                    color: inherit;
                    border: none;
                    svg {
                        color: #6c757d;
                    }
                    &.focus,
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            :not(.header) {
                &:hover,
                &.editing {
                    h3 {
                        visibility: hidden;
                    }
                    .dropdown {
                        display: flex;
                    }
                }
            }
        }
        .assets {
            justify-content: center;
        }
    }

    .asset-groups {
        margin-right: -1rem;
        position: relative;
    }
    .designset {
        padding-top: 75%;
        position: relative;
        > div {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1rem;
        }
    }
    .download-row {
        border-bottom: dashed 1px gray;
        padding-bottom: 0.5rem;
        .btn {
            padding: 0;
        }
    }
    .hover-click {
        &:hover {
            color: #4090ee !important;
        }
    }
    .download-button {
        background-color: #fff;
        color: #666;
        border: none;
        padding: 0;
        white-space: nowrap;
        position: relative;

        &.active,
        &:active {
            background-color: #fff !important;
            color: #666 !important;
            border: none;
            padding: 0;
            white-space: nowrap;
            position: relative;
        }

        .loading {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 1) url(/img/loading.svg) no-repeat center/contain;
            background-size: auto 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: none;
        }

        &.downloading .loading {
            display: block;
        }
    }
`;

const Placeholder = ({ asset, savePlaceholder }) => {
    const [editing, setEditing] = useState();
    const placeholder = useRef();
    const textarea = useRef();
    useEffect(() => {
        const stopEdit = (event) => {
            if (placeholder.current && !placeholder.current.contains(event.target)) {
                setEditing(false);
            }
        };
        window.addEventListener('click', stopEdit);

        return () => {
            window.removeEventListener('click', stopEdit);
        };
    }, []);

    const save = () => {
        savePlaceholder({
            asset,
            description: textarea.current.value,
        });
        setTimeout(() => setEditing(false), 100);
    };

    return (
        <div
            className={`placeholder d-flex flex-column ${editing ? 'editing' : ''}`}
            onClick={() => setEditing(true)}
            ref={placeholder}
        >
            <textarea
                className="d-flex flex-grow-1"
                ref={textarea}
                defaultValue={asset.description}
            />
            <div className="justify-content-between align-items-center actions">
                <Button outline size="sm" onClick={save}>
                    Save
                </Button>
                <FontAwesomeIcon
                    icon={['fa', 'download']}
                    onClick={() => saveAs(asset.viewUrl, asset.name)}
                    className="hover-click"
                />
            </div>
        </div>
    );
};

const Artwork = ({ asset, setModal, sessionId }) => {
    const [viewing, setViewing] = useState();
    const artwork = useRef();
    useEffect(() => {
        const stopViewing = (event) => {
            if (artwork.current && !artwork.current.contains(event.target)) {
                setViewing(false);
            }
        };
        window.addEventListener('click', stopViewing);

        return () => {
            window.removeEventListener('click', stopViewing);
        };
    }, []);

    return (
        <div
            className={`artwork d-flex flex-column ${viewing ? 'viewing' : ''}`}
            onClick={() => setViewing(true)}
            ref={artwork}
        >
            <img
                src={`${apiUrl}/downloadThumbnail?${asset.thumbnailUrl
                    .split('?')[1]
                    .replace('ID=', 'documentID=')
                    .replace('documentVersionID=', 'versionID=')}&X-WF-SESSION-ID=${sessionId}`}
                className="art"
            />
            <div className="versions flex-column">
                {asset.versions.reverse().map((version, index) => (
                    <div key={`version:${version.id}`} className="version flex-column">
                        <h5 className="mb-0">{version.label}</h5>
                        <div className="d-flex justify-content-between align-items-center flex-row meta">
                            <div>{version.uploadDate}</div>
                            <div className="ver">ver. {asset.versions.length - index}</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center flex-row actions">
                            {version.size}
                            <FontAwesomeIcon
                                icon={['fa', 'download']}
                                className="ml-2 hover-click"
                                onClick={() => saveAs(version.downloadUrl, asset.name)}
                                style={{ cursor: 'pointer' }}
                            />
                            <FontAwesomeIcon
                                icon={['fa', 'eye']}
                                className="ml-2 hover-click"
                                onClick={() =>
                                    setModal({
                                        preview: version.thumbnailUrl,
                                        title: version.label,
                                    })
                                }
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            {asset.status === 'Approved' ? (
                <FontAwesomeIcon
                    icon={['fas', 'check-circle']}
                    className="icon-success text-success"
                />
            ) : null}
        </div>
    );
};

const AssetGroup = ({
    element,
    designSet,
    header,
    groupPosition,
    savePlaceholder,
    saveGroup,
    designSetIndex,
    setModal,
    sessionId,
}) => {
    const [settingsOpen, setSettingsOpen] = useState();
    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSettingsOpen(false);
        saveGroup({
            designSet:
                designSet.designSetIndex !== undefined ? designSet.designSetIndex : designSetIndex,
            oldPosition: (designSet.startPosition || 0) + groupPosition,
            columns: Math.min(parseInt(event.target.elements.columns.value, 10), maxColumnSize),
            position: parseInt(event.target.elements.position.value, 10),
        });
    };

    const calculateColumns = () => {
        if (element.columns === -1) {
            return Math.min(
                element.assets.length >= 0 ? Math.ceil(element.assets.length / 3) : 1,
                maxColumnSize
            );
        }

        return Math.min(element.columns, maxColumnSize);
    };

    // console.log(element.name, designSetIndex, groupPosition);

    return (
        <div
            className={`d-flex flex-column asset-group${header ? ' asset-group-header' : ''}`}
            data-cols={calculateColumns()}
        >
            <div
                className={`asset-title d-flex align-items-center justify-content-center mb-3${
                    settingsOpen ? ' editing' : ''
                }${header ? ' header' : ''}`}
            >
                <h3 style={{ whiteSpace: 'nowrap' }} className="mb-0">
                    {element.name}
                </h3>
                <Dropdown isOpen={settingsOpen} toggle={() => setSettingsOpen(!settingsOpen)}>
                    <DropdownToggle className="mx-auto">
                        <FontAwesomeIcon icon={['fa', 'cog']} />
                    </DropdownToggle>
                    <DropdownMenu right className="p-3">
                        <h3 style={{ whiteSpace: 'nowrap' }} className="mb-3">
                            Design Element Settings
                        </h3>
                        <Form onSubmit={save}>
                            <div className="d-flex">
                                <FormGroup>
                                    <Label for={`columns:${groupPosition}`} className="d-block">
                                        Columns
                                    </Label>
                                    <Input
                                        name="columns"
                                        type="number"
                                        id={`columns:${groupPosition}`}
                                        defaultValue={calculateColumns()}
                                        style={{ width: '4em' }}
                                    />
                                </FormGroup>
                                <FormGroup className="ml-3">
                                    <Label for={`position:${groupPosition}`} className="d-block">
                                        Position
                                    </Label>
                                    <Input
                                        name="position"
                                        type="number"
                                        id={`position:${groupPosition}`}
                                        defaultValue={
                                            (designSet.startPosition || 0) +
                                            (groupPosition || 0) +
                                            1
                                        }
                                        style={{ width: '4em' }}
                                    />
                                </FormGroup>
                            </div>
                            <div className="d-flex justify-content-between">
                                <Button type="submit" outline>
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="link"
                                    onClick={() => setSettingsOpen(false)}
                                    style={{ color: 'inherit' }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div
                className="assets d-flex flex-row flex-wrap mx-auto"
                style={{
                    width: `${
                        /* prettier-ignore */
                        element.name === "Banner" && element.assets.length <= 1 ? Math.ceil(assetWidth * 4.288) : calculateColumns() * (assetWidth + assetSpacing)
                    }px`,
                }}
            >
                {element.assets.map((asset) => (
                    <figure
                        key={`asset:${asset.id}`}
                        className={
                            element.name === 'Banner' && element.assets.length <= 1
                                ? 'banner'
                                : undefined
                        }
                    >
                        {asset.isPlaceholder ? (
                            <Placeholder
                                asset={asset}
                                element={element}
                                savePlaceholder={savePlaceholder}
                            />
                        ) : (
                            <Artwork asset={asset} setModal={setModal} sessionId={sessionId} />
                        )}
                        <figcaption className={asset.status}>
                            {(asset.name || '').replace('.null', '')}
                        </figcaption>
                    </figure>
                ))}
            </div>
        </div>
    );
};

const Page = ({
    designSet,
    assetMap,
    saveAllPages,
    savePlaceholder,
    saveGroup,
    designSetIndex,
    setModal,
    sessionId,
}) => {
    const page = useRef();
    const [downloading, setDownloading] = useState();
    const [downloadingAll, setDownloadingAll] = useState();

    useEffect(() => {
        if (downloadingAll) {
            window.setTimeout(() => {
                saveAllPages(() => {
                    setDownloadingAll(false);
                });
            }, 100);
        }
    }, [downloadingAll, saveAllPages]);

    const savePage = (el, name) => {
        setDownloading(true);
        domtoimage
            .toPng(el, {
                cacheBust: true,
                bgcolor: '#fff',
                filter: (node) => !node.classList || !node.classList.contains('dont-print'),
            })
            .then((blob) => {
                saveAs(blob, `${name}.png`);
                setDownloading(false);
            });
    };

    return (
        <div className="designset" ref={page}>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-4">
                    <div>
                        <h1
                            style={{
                                visibility:
                                    designSet.designSetSubIndex === 0 ||
                                    designSet.designSetSubIndex === undefined
                                        ? 'visible'
                                        : 'hidden',
                            }}
                        >
                            {assetMap.projectName}
                        </h1>
                    </div>
                    <div className="text-right d-flex flex-grow-0 flex-nowrap  ml-auto">
                        <h2 className="ml-auto mr-3" style={{ whiteSpace: 'nowrap' }}>
                            {designSet.name}
                        </h2>
                        {designSet.designSetSubIndex && designSet.designSetSubIndex > 0 ? (
                            <h2
                                className="mr-3"
                                style={{
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'normal',
                                    textTransform: 'lowercase',
                                }}
                            >
                                (cont)
                            </h2>
                        ) : null}
                        <div className="d-flex">
                            {assetMap.designSets.map((set, index) => (
                                <div
                                    key={`page:${index.toString()}`}
                                    className={`rounded-circle ${
                                        index ===
                                        parseInt(
                                            designSet.designSetIndex !== undefined
                                                ? designSet.designSetIndex
                                                : designSetIndex,
                                            10
                                        )
                                            ? 'active'
                                            : ''
                                    }`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {designSet.designSetSubIndex === 0 || designSet.designSetSubIndex === undefined ? (
                    <div
                        className="d-flex mb-4 justify-content-between"
                        style={{ marginRight: '-1rem' }}
                    >
                        {designSet.header.map((element, elementPosition) => (
                            <AssetGroup
                                element={element}
                                designSet={designSet}
                                header
                                key={`assetgroup:${element.name}:${
                                    designSet.designSetIndex || 0
                                }:${elementPosition.toString()}`}
                                savePlaceholder={savePlaceholder}
                                saveGroup={saveGroup}
                                groupPosition={elementPosition}
                                setModal={setModal}
                                sessionId={sessionId}
                            />
                        ))}
                        <div
                            className="asset-group legend d-flex align-items-center flex-column ml-auto"
                            style={{
                                visibility:
                                    designSet.designSetSubIndex === undefined ||
                                    (designSet.designSetSubIndex === 0 &&
                                        designSet.designSetSubIndex === 0)
                                        ? 'visible'
                                        : 'hidden',
                            }}
                        >
                            <div>
                                <h3>File Names</h3>
                                {[
                                    {
                                        name: 'Uploaded',
                                        color: '#000',
                                    },
                                    {
                                        name: 'Under Review',
                                        color: '#4090ee',
                                    },
                                    {
                                        name: 'Rejected',
                                        color: '#ea2a2a',
                                    },
                                    {
                                        name: 'Approved',
                                        color: '#6bb942',
                                    },
                                ].map((status) => (
                                    <div
                                        key={`legend:${status.name}`}
                                        className="d-flex align-items-center status"
                                    >
                                        <div
                                            className="color"
                                            style={{ backgroundColor: status.color }}
                                        />
                                        <span style={{ whiteSpace: 'nowrap' }}>{status.name}</span>
                                    </div>
                                ))}
                            </div>
                            <Button
                                style={{
                                    marginTop: '10px',
                                }}
                                onClick={() => setDownloadingAll(true)}
                                className={`dont-print hover-click download-button${
                                    downloadingAll ? ' downloading' : ''
                                }`}
                                disabled={downloadingAll}
                            >
                                <div className="loading" />
                                <span>
                                    <FontAwesomeIcon icon={['fa', 'download']} className="mr-2" />
                                    Download All
                                </span>
                            </Button>
                        </div>
                    </div>
                ) : null}
                <div
                    className="d-flex flex-wrap asset-groups flex-grow-1 justify-content-between"
                    style={{ overflow: 'auto' }}
                >
                    {designSet.designElements.map((element, elementPosition) => (
                        <AssetGroup
                            element={element}
                            designSet={designSet}
                            designSetIndex={designSetIndex}
                            key={`assetgroup:${element.name}:${
                                designSet.designSetIndex || 0
                            }:${elementPosition.toString()}`}
                            groupPosition={elementPosition}
                            savePlaceholder={savePlaceholder}
                            saveGroup={saveGroup}
                            setModal={setModal}
                            sessionId={sessionId}
                        />
                    ))}
                </div>
                <div className="text-right download-row dont-print">
                    <Button
                        disabled={downloading}
                        onClick={() =>
                            savePage(
                                page.current,
                                `${designSet.name}${
                                    designSet.designSetSubIndex > 0
                                        ? ` - cont ${designSet.designSetSubIndex}`
                                        : ''
                                }`
                            )
                        }
                        className={`hover-click download-button${
                            downloading ? ' downloading' : ''
                        }`}
                    >
                        <div className="loading" />
                        <span>
                            DOWNLOAD
                            <FontAwesomeIcon icon={['fa', 'download']} className="ml-3" />
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

const AssetMap = () => {
    let sessionId = store.session('X-WF-SESSION-ID');
    const location = useLocation();
    const parseQS = queryString.parse(location.search);

    if (!sessionId && parseQS['X-WF-SESSION-ID']) {
        store.session('X-WF-SESSION-ID', parseQS['X-WF-SESSION-ID']);
        sessionId = parseQS['X-WF-SESSION-ID'];
    }

    const { assetMapId } = useParams();
    const container = useRef();

    const [assetMap, setAssetMap] = useState();
    const [adjustedAssetMap, setAdjustedAssetMap] = useState();
    const [loading, setLoading] = useState(true);
    const [pageNotFound, setPageNotFound] = useState();
    const [modal, setModal] = useState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // window.scrollTo(0, 0);
        // console.log('Render check');
        if (assetMap) {
            let setInfo = {};
            const designSets = [];
            const sets =
                container && container.current
                    ? Array.from(container.current.getElementsByClassName('asset-groups'))
                    : [];

            if (sets.length) {
                (adjustedAssetMap ? adjustedAssetMap.designSets : assetMap.designSets).map(
                    (set, setIndex) => {
                        setInfo = {
                            designSetIndex: adjustedAssetMap ? set.designSetIndex : setIndex,
                            designSetSubIndex: set.designSetSubIndex || 0,
                            startPosition: set.startPosition || 0,
                        };

                        if (sets[setIndex].scrollHeight > sets[setIndex].clientHeight) {
                            // update = true;
                            const groups = sets[setIndex].getElementsByClassName('asset-group');
                            const rows = {};
                            let currentGroup = [];
                            let newGroup = [];

                            Array.from(groups).map((group) => {
                                if (!rows[group.offsetTop]) {
                                    rows[group.offsetTop] = [];
                                }
                                rows[group.offsetTop].push(group);

                                return null;
                            });

                            // debugger;
                            let overflowed = false;
                            Object.keys(rows)
                                .sort((a, b) => parseInt(a, 10) > parseInt(b, 10))
                                .map((offsetTop) => {
                                    let maxHeight = 0;
                                    rows[offsetTop].map((element) => {
                                        maxHeight = Math.max(maxHeight, element.offsetHeight);

                                        return null;
                                    });
                                    if (
                                        !overflowed &&
                                        parseInt(offsetTop, 10) + maxHeight <=
                                            sets[setIndex].clientHeight
                                    ) {
                                        currentGroup = currentGroup.concat(rows[offsetTop]);
                                    } else {
                                        overflowed = true;
                                        newGroup = newGroup.concat(rows[offsetTop]);
                                    }

                                    return null;
                                });

                            if (currentGroup.length === 0 || newGroup.length === 0) {
                                // / nothing to do for now, nothing should update?
                                designSets.push({
                                    ...set,
                                    ...setInfo,
                                });
                            } else {
                                designSets.push({
                                    ...set,
                                    designElements: (adjustedAssetMap || assetMap).designSets[
                                        setIndex
                                    ].designElements.slice(0, currentGroup.length),
                                    ...setInfo,
                                });

                                designSets.push({
                                    ...set,
                                    designElements: (adjustedAssetMap || assetMap).designSets[
                                        setIndex
                                    ].designElements.slice(
                                        currentGroup.length,
                                        currentGroup.length + newGroup.length
                                    ),
                                    ...setInfo,
                                    designSetSubIndex: setInfo.designSetSubIndex + 1,
                                    startPosition: setInfo.startPosition + currentGroup.length,
                                });

                                // if (
                                //     assetMap.designSets[setInfo.designSetIndex].designElements
                                //         .length >
                                //     setInfo.designSetSubIndex + currentGroup.length
                                // ) {
                                //     designSets.push({
                                //         ...set,
                                //         designElements: assetMap.designSets[
                                //             setInfo.designSetIndex
                                //         ].designElements.slice(
                                //             setInfo.designSetSubIndex + currentGroup.length,
                                //             assetMap.designSets[setInfo.designSetIndex]
                                //                 .designElements.length
                                //         ),
                                //         ...setInfo,
                                //         designSetSubIndex: setInfo.designSetSubIndex + 1,
                                //         startPosition: setInfo.startPosition + currentGroup.length,
                                //     });
                                // }
                            }
                        } else {
                            designSets.push({
                                ...set,
                                ...setInfo,
                            });
                        }

                        return null;
                    }
                );

                if (designSets.length !== sets.length) {
                    setAdjustedAssetMap({
                        projectName: assetMap.projectName,
                        designSets,
                    });
                }
            }
        }
    });

    const fetchAssetMap = async () => {
        const assetMapData = await getAssetMap(assetMapId);

        // console.log('loading', assetMapId, assetMapData);

        if (!assetMapData) {
            setPageNotFound(true);
        } else {
            setAssetMap(assetMapData);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchAssetMap();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetMapId]);

    const save = (newAssetMap, refreshData) => {
        const saveNewAssetMap = {
            sets: newAssetMap.designSets.map((set) => ({
                e: set.designElements.map((group) => ({
                    n: group.name,
                    c: group.columns,
                })),
            })),
        };
        // console.log('saving', assetMapId, newAssetMap, saveNewAssetMap);
        saveAssetMap(assetMapId, saveNewAssetMap);
        if (refreshData) {
            setAdjustedAssetMap();
            setAssetMap(newAssetMap);
        }
    };

    const savePlaceholder = ({ asset, description }) => {
        updateAssetDescription(asset.id, description);
    };

    const saveGroup = ({ designSet, oldPosition, columns, position }) => {
        const move = (array, from, to) => {
            let numberOfDeletedElm = 1;
            const elm = array.splice(from, numberOfDeletedElm)[0];
            numberOfDeletedElm = 0;
            array.splice(to, numberOfDeletedElm, elm);
        };
        const newAssetMap = { ...assetMap };

        newAssetMap.designSets[designSet].designElements[oldPosition].columns = columns;
        const newPosition = Math.min(
            Math.max(position - 1, 0),
            newAssetMap.designSets[designSet].designElements.length - 1
        );
        if (oldPosition !== newPosition) {
            move(newAssetMap.designSets[designSet].designElements, oldPosition, newPosition);
        }

        save(newAssetMap, true);
    };

    const saveAllPages = async (onComplete) => {
        const pages = container.current.getElementsByClassName('designset');

        Promise.all(
            Array.from(pages, (page) =>
                domtoimage
                    .toBlob(page, {
                        bgcolor: '#fff',
                        filter: (node) => !node.classList || !node.classList.contains('dont-print'),
                    })
                    .then((blob) => blob)
            )
        ).then((screenshots) => {
            const zip = new JSZip();
            (adjustedAssetMap ? adjustedAssetMap.designSets : assetMap.designSets).map(
                (designSet, designSetIndex) => {
                    zip.file(
                        `${designSet.name}${
                            designSet.designSetSubIndex > 0
                                ? ` - cont ${designSet.designSetSubIndex}`
                                : ''
                        }.png`,
                        screenshots[designSetIndex]
                    );

                    return null;
                }
            );

            zip.generateAsync({ type: 'blob' }).then((blob) => {
                saveAs(blob, `${assetMap.projectName}.zip`);
                onComplete();
            });
        });
    };

    return loading ? (
        <Loading container loading={loading} pageNotFound={pageNotFound} className="px-0" />
    ) : (
        <div ref={container}>
            <Helmet
                htmlAttributes={{
                    'data-page': 'asset-map',
                }}
                title="Asset Map"
            />
            <StyledContainer ref={container}>
                <Loading
                    loading={loading}
                    pageNotFound={pageNotFound}
                    container
                    fluid
                    id="assetmap-loading"
                >
                    {(adjustedAssetMap
                        ? adjustedAssetMap.designSets
                        : assetMap
                        ? assetMap.designSets
                        : []
                    ).map((designSet, designSetIndex) => (
                        <Page
                            key={`designSet:${designSetIndex.toString()}`}
                            designSet={designSet}
                            designSetIndex={designSetIndex}
                            assetMap={assetMap}
                            saveAllPages={saveAllPages}
                            savePlaceholder={savePlaceholder}
                            saveGroup={saveGroup}
                            setModal={setModal}
                            sessionId={sessionId}
                        />
                    ))}
                    {modal ? (
                        <Modal isOpen toggle={() => setModal()} size="lg">
                            <ModalHeader toggle={() => setModal()}>{modal.title}</ModalHeader>
                            <ModalBody>
                                <img src={modal.preview} width="100%" />
                            </ModalBody>
                        </Modal>
                    ) : null}
                </Loading>
            </StyledContainer>
        </div>
    );
};

export default AssetMap;
