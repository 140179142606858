/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useBeforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fieldset from './fieldSet';
import ThinkingButton from '../thinkingButton';

const SubmittingOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.5);
`;

const CSForm = (props) => {
    const {
        forms,
        readOnly,
        submitForm,
        submitButtonLabel,
        collapseForms,
        submitByDefault,
        dropdownOptions,
        setDefaultValues,
        generateDocuments,
    } = props;
    const [fieldsByKey, setFieldsByKey] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [formsOpened, setFormsOpened] = useState([]);

    useBeforeunload(() => {
        if (formChanged) {
            return `You have unsaved changes, are you sure you want to leave?`;
        }

        return null;
    });

    useEffect(() => {
        const initFieldsByKey = {};
        const openedForms = [];

        if (forms.length) {
            forms.map((form, formCount) => {
                form.sections.map((section) => {
                    section.rows.map((row) => {
                        row.fields.map((field) => {
                            if (field) {
                                initFieldsByKey[field.name] = {
                                    ...field,
                                    displayLogicRules: (field.displayLogicRules || []).concat(
                                        section.displayLogicRules || []
                                    ),
                                };
                            }

                            return null;
                        });

                        return null;
                    });

                    return null;
                });

                openedForms.push(formCount === 0);

                return null;
            });
        }

        setFormsOpened(openedForms);
        setFieldsByKey(initFieldsByKey);
    }, [forms]);

    const updateFormValue = (updatedField) => {
        setFieldsByKey((prev) => {
            const updatedFormValues = { ...prev };
            updatedFormValues[updatedField.name] = updatedField;

            if (updatedField.id !== 'uploadDocuments') {
                setFormChanged(true);
            }

            return updatedFormValues;
        });
    };

    const shouldDisplay = (rules, formValues) => {
        let display = false;

        (rules || []).map((rule) => {
            if (!display) {
                let matched = true;

                rule.matches.map((match) => {
                    const matchValue = formValues[match.fieldName]
                        ? formValues[match.fieldName].value
                        : '';
                    if (matched) {
                        matched =
                            (match.matchType === 'EXIST' &&
                                (Array.isArray(matchValue)
                                    ? matchValue.indexOf(match.value) > -1
                                    : match.value === matchValue)) ||
                            (match.matchType === 'NOTEXIST' &&
                                (Array.isArray(matchValue)
                                    ? matchValue.indexOf(match.value) === -1
                                    : match.value !== matchValue));
                    }

                    return null;
                });
                if (matched) {
                    display = true;
                }
            }

            return null;
        });

        return display;
    };

    const checkRequired = useCallback(
        (markDirty) => {
            const submitFormValues = {};
            const newFieldsByKey = {};
            let canSubmitForm = true;

            Object.keys(fieldsByKey).map((fieldName) => {
                const field = fieldsByKey[fieldName];

                if (markDirty) {
                    field.isDirty = true;
                }
                newFieldsByKey[fieldName] = field;

                let { value } = field;
                let empty = true;

                if (typeof value === 'boolean') {
                    empty = false;
                } else if (Array.isArray(value)) {
                    if (value.length && value[0] !== null) {
                        empty = false;
                    } else {
                        empty = true;
                        value = '';
                    }
                } else if (value) {
                    empty = false;
                }

                if (
                    field.isRequired &&
                    field.isEditable &&
                    empty &&
                    (!field.displayLogicRules.length ||
                        shouldDisplay(field.displayLogicRules, fieldsByKey))
                ) {
                    canSubmitForm = false;
                }

                if ((value || value === '') && field.displayType !== 'CALC') {
                    if (Array.isArray(value) && value[0].label && value[0].value) {
                        value = value.map((val) => val.value);
                    }

                    if (value && value.value) {
                        value = value.value;
                    }

                    submitFormValues[fieldName] = value;
                }

                return null;
            });

            return {
                newFieldsByKey,
                canSubmitForm,
                submitFormValues,
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldsByKey]
    );

    const checkForm = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        window.setTimeout(async () => {
            setSubmitting(true);
        }, 100);

        const requiredValues = checkRequired(true);
        setFieldsByKey(requiredValues.newFieldsByKey);

        if (requiredValues.canSubmitForm) {
            setFormChanged(false);
            await submitForm(requiredValues.submitFormValues);
            window.setTimeout(() => {
                setSubmitting(false);
            }, 100);
        } else {
            toast.dismiss();
            toast.error('Required form fields are missing.');
            const errors = document.getElementsByClassName('is-invalid');
            if (errors.length) {
                errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            window.setTimeout(() => setSubmitting(false), 100);
        }
    };

    // check if we can submit on form load
    useEffect(() => {
        const { canSubmitForm } = checkRequired();
        setCanSubmit(canSubmitForm);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldsByKey]);

    return (
        <Row>
            <Col>
                {submitting ? <SubmittingOverlay /> : null}
                <Prompt
                    when={formChanged}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                {forms.length
                    ? forms.map((form, count) =>
                          form.sections.length ? (
                              <div
                                  className="form"
                                  key={`form:${count.toString()}`}
                                  id={`form:${count.toString()}`}
                              >
                                  {form.name ? (
                                      <legend
                                          className={collapseForms ? 'clickable' : ''}
                                          onClick={() => {
                                              if (collapseForms) {
                                                  setFormsOpened((prevValues) => {
                                                      const thisForms = prevValues.slice();
                                                      thisForms[count] = !thisForms[count];

                                                      return thisForms;
                                                  });
                                              }
                                          }}
                                      >
                                          {collapseForms ? (
                                              formsOpened[count] ? (
                                                  <FontAwesomeIcon
                                                      icon={['fa', 'chevron-down']}
                                                      className="mr-3"
                                                  />
                                              ) : (
                                                  <FontAwesomeIcon
                                                      icon={['fa', 'chevron-right']}
                                                      className="mr-3"
                                                  />
                                              )
                                          ) : null}
                                          {form.name}
                                      </legend>
                                  ) : null}
                                  <div
                                      className={
                                          !collapseForms || formsOpened[count]
                                              ? 'd-block'
                                              : 'd-none'
                                      }
                                  >
                                      {form.description ? (
                                          <p className="mt-3">{form.description}</p>
                                      ) : null}
                                      {form.sections.map((section, subCount) =>
                                          shouldDisplay(section.displayLogicRules, fieldsByKey) ||
                                          (section.displayLogicRules || []).length === 0 ? (
                                              <Fieldset
                                                  key={`section:${count.toString()}:${
                                                      section.id
                                                  }:${count.toString()}::${subCount.toString()}`}
                                                  section={section}
                                                  onChange={updateFormValue}
                                                  fieldsByKey={fieldsByKey}
                                                  setFieldsByKey={setFieldsByKey}
                                                  readOnly={readOnly}
                                                  shouldDisplay={shouldDisplay}
                                                  dropdownOptions={dropdownOptions}
                                                  setDefaultValues={setDefaultValues}
                                                  generateDocuments={generateDocuments}
                                                  submitForm={checkForm}
                                              />
                                          ) : null
                                      )}
                                  </div>

                                  <hr />
                              </div>
                          ) : null
                      )
                    : null}
                {!readOnly ? (
                    <div className="d-inline-flex position-relative mb-5">
                        <div
                            className={`position-absolute w-100 h-100 ${
                                submitByDefault !== true && !canSubmit ? 'd-block' : 'd-none'
                            }`}
                            id="RequiredTooltip"
                            style={{ zIndex: '1', cursor: 'not-allowed' }}
                        />
                        <ThinkingButton
                            type="button"
                            thinking={submitting}
                            outline
                            color="primary"
                            disabled={submitByDefault !== true && !canSubmit}
                            onClick={() => checkForm(null)}
                        >
                            {submitButtonLabel}
                        </ThinkingButton>
                        {submitByDefault !== true && !canSubmit ? (
                            <UncontrolledTooltip placement="right" target="RequiredTooltip">
                                Required Form Fields Missing
                            </UncontrolledTooltip>
                        ) : null}
                    </div>
                ) : null}
            </Col>
        </Row>
    );
};

CSForm.propTypes = {
    forms: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    readOnly: PropTypes.bool,
    submitForm: PropTypes.func,
    setDefaultValues: PropTypes.func,
    generateDocuments: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    submitByDefault: PropTypes.bool,
    collapseForms: PropTypes.bool,
    dropdownOptions: PropTypes.objectOf(PropTypes.any),
};
CSForm.defaultProps = {
    forms: [],
    readOnly: false,
    submitForm: () => {},
    setDefaultValues: () => {},
    generateDocuments: () => {},
    submitButtonLabel: 'Save',
    submitByDefault: false,
    collapseForms: false,
    dropdownOptions: {},
};

export default CSForm;
