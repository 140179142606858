export function updateForm(data) {
    (data || []).map((form) => {
        form.sections.map((section) => {
            section.rows.map((row) => {
                row.fields.map((field) => {
                    if (field && field.name === 'Number of Design Sets') {
                        field.showGenerateDocumentButton = true;
                    }
                    return null;
                });
                return null;
            });
            return null;
        });
        return null;
    });
    return data;
}
