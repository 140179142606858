import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Card, CardImg, CardText, CardBody, Col, Row } from 'reactstrap';
import slugify from 'slugify';
import PropTypes from 'prop-types';
import Loading from './loading';

const drawCard = (queueDefinition) => {
    const { projectID, name, description, imageUrlForNavigation } = queueDefinition;

    let title = name.replace('Service Requests', '').trim();
    title = title.replace('Service Request', '').trim();
    const slug = slugify(title.toLowerCase());

    return (
        <Col className="my-3" key={projectID} xs="auto">
            <Card style={{ width: '350px', borderRadius: '12px', overflow: 'hidden' }}>
                <Link to={`/request/${projectID}`}>
                    <CardImg
                        top
                        src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                        alt={name}
                        style={{
                            backgroundColor: `#d9d9d9`,
                            background: `#d9d9d9 url(${
                                imageUrlForNavigation || `/img/art/${slug}.jpg`
                            }) no-repeat`,
                            height: '250.179px',
                        }}
                    />
                </Link>
                <CardBody style={{ height: '115.751px' }}>
                    <h3 style={{ fontSize: '48px', marginBottom: '0' }}>{title}</h3>
                    <h4 style={{ fontSize: '24px' }}>Service Request</h4>
                    <CardText>{description}</CardText>
                </CardBody>
            </Card>
        </Col>
    );
};

const Home = (props) => {
    const { queueDefinitions, definitionsLoading, definitionsError } = props;

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'home',
                }}
                title="Home"
            />
            <Loading
                container
                loading={definitionsLoading}
                error={definitionsError}
                className="px-0"
            >
                <Row>
                    <Col md="12" className="mt-3">
                        <h1 style={{ fontSize: '36px' }}>Submit a Request</h1>
                    </Col>
                </Row>
                <Row className="justify-content-start">
                    {queueDefinitions.map((queueDefinition, count) =>
                        drawCard(queueDefinition, count)
                    )}
                </Row>
            </Loading>
        </>
    );
};

Home.propTypes = {
    queueDefinitions: PropTypes.arrayOf(PropTypes.object),
    definitionsLoading: PropTypes.bool,
    definitionsError: PropTypes.bool,
};
Home.defaultProps = {
    queueDefinitions: [],
    definitionsLoading: true,
    definitionsError: false,
};

export default Home;
