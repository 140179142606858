import React, { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FormGroup, Label, Col, Row } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Loading from './loading';
import { getCustomFormsForQueueTopic, searchUsers, createRequest } from '../utils/api';
import Form from '../components/forms/form';
import * as CharacterSearch from '../components/forms/characterSearch';
import { AppContext } from '../context';
import { buildStyleGuideForm, setDefaultValues } from '../components/forms/styleGuideSection';

const Request = (props) => {
    const { match, queueDefinitions, definitionsLoading, definitionsError, history } = props;
    const { projectId, topicId: topicIdProp } = match.params;

    const [loading, setLoading] = useState(true);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [project, setProject] = useState({});

    const [topic, setTopic] = useState();
    const [topicId, setTopicId] = useState(topicIdProp);

    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState(false);

    const [topicSelections, setTopicSelections] = useState([]);

    const appData = useContext(AppContext);

    useEffect(() => {
        const findProject = async () => {
            let foundProject = null;
            queueDefinitions.map((definition) => {
                if (definition.projectID === projectId) {
                    foundProject = definition;
                }

                return null;
            });
            setLoading(false);
            if (foundProject) {
                setProject(foundProject);
            } else {
                setPageNotFound(true);
            }
        };
        if (queueDefinitions.length) {
            findProject();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, queueDefinitions]);

    const selectRequestTopic = (choice, level) => {
        const newSelections = topicSelections.slice(0, level + 1);

        newSelections[level] = {
            selected: choice,
            selectOptions: newSelections[level].selectOptions,
        };
        if (choice.selectOptions && choice.selectOptions.length) {
            newSelections.push({
                selected: null,
                selectOptions: choice.selectOptions,
            });
        }

        setTopicSelections(newSelections);
        setTopicId(choice.id || null);
        if (!choice.id) {
            setTopic(null);
        }
    };

    const getOptions = (useQueueTopic) => {
        const returnOptions = [];

        if (useQueueTopic.queueTopicGroups && useQueueTopic.queueTopicGroups.length) {
            useQueueTopic.queueTopicGroups.map((group) => {
                returnOptions.push({
                    label: group.name,
                    value: group.id || group.name,
                    selectOptions: getOptions(group),
                });

                return null;
            });
        }
        if (useQueueTopic.queueTopics && useQueueTopic.queueTopics.length) {
            useQueueTopic.queueTopics.map((queueTopic) => {
                returnOptions.push({
                    label: queueTopic.name,
                    value: queueTopic.name,
                    id: queueTopic.id,
                });

                return null;
            });
        }
        return returnOptions;
    };

    useEffect(() => {
        if (project && Object.keys(project).length && topicSelections.length === 0) {
            const generatedOptions = [
                {
                    selected: null,
                    selectOptions: getOptions(project),
                },
            ];
            setTopicSelections(generatedOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    // go get the form
    const franchiseRef = useRef();
    useEffect(() => {
        const fetchForm = async () => {
            let data = await getCustomFormsForQueueTopic(topicId);
            setFormLoading(false);
            if (!data) {
                setFormError(true);
            } else {
                data = CharacterSearch.updateForm(data, franchiseRef);
                setTopic(data);
            }
        };
        if (topicId) {
            setFormLoading(true);
            fetchForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicId]);

    const beforeForms = {
        description: null,
        name: null,
        sections: [
            {
                id: 'requestNameSection',
                name: '',
                description: '',
                displayLogicRules: [],
                rows: [
                    {
                        fields: [
                            {
                                id: 'requestName',
                                name: 'Request Name',
                                value: null,
                                dataType: 'TEXT',
                                description: null,
                                displaySize: 80,
                                displayType: 'TEXT',
                                isRequired: true,
                                isEditable: true,
                                controlsDisplay: false,
                                displayLogicRules: [],
                                options: [],
                            },
                        ],
                    },
                ],
            },
            ...(project && project.showAssignedTo
                ? [
                      {
                          id: 'assignedToSection',
                          name: '',
                          description: '',
                          displayLogicRules: [],
                          rows: [
                              {
                                  fields: [
                                      {
                                          id: 'assignedTo',
                                          name: 'Assigned To',
                                          value: null,
                                          dataType: 'TEXT',
                                          description: null,
                                          displaySize: 80,
                                          displayType: 'SLCT',
                                          isRequired: false,
                                          isEditable: true,
                                          controlsDisplay: false,
                                          displayLogicRules: [],
                                          options: [],
                                          placeholder: 'Search...',
                                          dontConvertSelect: true,
                                          loadOptions: async (inputValue) => {
                                              const results = await searchUsers(inputValue);

                                              return results.map((result) => ({
                                                  value: result.id,
                                                  label: result.name,
                                              }));
                                          },
                                      },
                                  ],
                              },
                          ],
                      },
                  ]
                : []),
            ...(project && project.showPrimaryContact
                ? [
                      {
                          id: 'primaryContactSection',
                          name: '',
                          description: '',
                          displayLogicRules: [],
                          rows: [
                              {
                                  fields: [
                                      {
                                          id: 'primaryContact',
                                          name: 'Primary Contact',
                                          value:
                                              appData &&
                                              appData.user &&
                                              appData &&
                                              appData.user.id &&
                                              appData &&
                                              appData.user.name
                                                  ? {
                                                        value: appData.user.id,
                                                        label: appData.user.name,
                                                    }
                                                  : null,
                                          dataType: 'TEXT',
                                          description: null,
                                          displaySize: 80,
                                          displayType: 'SLCT',
                                          isRequired: false,
                                          isEditable: true,
                                          controlsDisplay: false,
                                          displayLogicRules: [],
                                          options: [],
                                          placeholder: 'Search...',
                                          dontConvertSelect: true,
                                          loadOptions: async (inputValue) => {
                                              const results = await searchUsers(inputValue);

                                              return results.map((result) => ({
                                                  value: result.id,
                                                  label: result.name,
                                              }));
                                          },
                                      },
                                  ],
                              },
                          ],
                      },
                  ]
                : []),
        ],
    };

    const afterForms = {
        description: null,
        name: null,
        sections: [
            {
                id: 'uploadDocumentsSection',
                name: '',
                description: '',
                displayLogicRules: [],
                rows: [
                    {
                        fields: [
                            {
                                id: 'uploadDocuments',
                                name: 'Upload Files',
                                value: null,
                                dataType: 'FILE',
                                description: null,
                                displaySize: 80,
                                displayType: 'FILE',
                                isEditable: true,
                                controlsDisplay: false,
                                displayLogicRules: [],
                                multiple: true,
                            },
                        ],
                    },
                ],
            },
        ],
    };

    const forms = [];
    if (topic) {
        forms.push(beforeForms);
        (topic || []).map((form) => {
            if (form.name.includes('Branding Guide') || form.name.includes('Style Guide') || form.name.includes('Packaging Guide')) {
                forms.push(buildStyleGuideForm(form));
            } else {
                forms.push(form);
            }
            return null;
        });
        if (project && project.showUploadDocument) {
            forms.push(afterForms);
        }
    }

    return (
        <Loading
            container
            loading={definitionsLoading || loading}
            error={definitionsError}
            pageNotFound={pageNotFound}
            className="px-0"
        >
            <Helmet
                htmlAttributes={{
                    'data-page': 'request',
                }}
                title="Request"
            />
            <Row>
                <Col md="9">
                    <h1 className="mt-3">{project.name}</h1>
                    <p>{project.description}</p>
                </Col>
            </Row>
            <FormGroup>
                <Label>Select a Request Type</Label>
                {(topicSelections || []).map((selection, count) => (
                    <div
                        className={count + 1 < topicSelections.length ? 'mb-3' : ''}
                        key={`queueTopics.selection:${count.toString()}`}
                    >
                        <Select
                            classNamePrefix="react-select"
                            value={selection.selected}
                            options={selection.selectOptions}
                            onChange={(choice) => {
                                selectRequestTopic(choice, count);
                            }}
                        />
                    </div>
                ))}
            </FormGroup>

            <Loading loading={formLoading} error={formError}>
                                {forms.length ? (

                    <Form
                        forms={forms}
                        setDefaultValues={setDefaultValues}
                        submitButtonLabel="Create Request"
                        submitByDefault
                        submitForm={async (formFields) => {
                            const {
                                'Request Name': name,
                                'Assigned To': assignedToId,
                                'Primary Contact': primaryContactId,
                                'Upload Files': fileUploads,
                                ...remainderFields
                            } = formFields;

                            const renamedFields = {};
                            Object.keys(remainderFields).map((fieldName) => {
                                renamedFields[`DE:${fieldName}`] = remainderFields[fieldName];

                                return null;
                            });

                            const getValue = (fieldValue) => {
                                const val = Array.isArray(fieldValue) ? fieldValue[0] : fieldValue;

                                return val && val.value ? val.value : val;
                            };
                            const files = fileUploads === '' ? null : fileUploads;

                            const formValues = {
                                projectID: projectId,
                                queueTopicID: topicId,
                                name,
                                assignedToId: getValue(assignedToId),
                                primaryContactId: getValue(primaryContactId),
                                fileUploads: files,
                                formFieldValues: renamedFields,
                            };

                            const createResult = await createRequest(formValues);
                            if (createResult) {
                                toast.success('Request created.');
                                history.push(`/thanks`);
                            } else {
                                // toast error is handled in the API, nothing to do here
                            }

                            return true;
                        }}
                    />
                ) : null}
            </Loading>
        </Loading>
    );
};

Request.propTypes = {
    match: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]).isRequired,
    queueDefinitions: PropTypes.arrayOf(PropTypes.object),
    definitionsLoading: PropTypes.bool,
    definitionsError: PropTypes.bool,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

Request.defaultProps = {
    queueDefinitions: [],
    definitionsLoading: true,
    definitionsError: false,
};

export default Request;
