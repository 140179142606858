import React from 'react';
import ThinkingButton from "../thinkingButton";


const StyleGuideButton = (props) => {
    const {
        toggle    } = props;


    return ( <ThinkingButton
            type="button"
            outline
            color="primary"
            onClick={()=>{
                toggle();
            }}
        >
            {"Populate Fields"}
        </ThinkingButton>
    );
};
StyleGuideButton.propTypes = {

};
StyleGuideButton.defaultProps = {

};
export default StyleGuideButton;
