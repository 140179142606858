import { searchCharacters, searchKeywords, searchTitles } from '../../utils/api';

export const getFormField = (forms, fieldName) => {
    let returnValue = null;
    (forms || []).map((form) => {
        form.sections.map((section) => {
            section.rows.map((row) => {
                row.fields.map((field) => {
                    if (field.name === fieldName) {
                        returnValue = field.value;
                    }

                    return null;
                });

                return null;
            });

            return null;
        });

        return null;
    });

    return returnValue;
};

const properSort = (a, b) => {
    const compareA =
        parseFloat(a.label).toString().length === a.label.length
            ? parseFloat(a.label)
            : a.label.toUpperCase();
    const compareB =
        parseFloat(b.label).toString().length === b.label.length
            ? parseFloat(b.label)
            : b.label.toUpperCase();

    let comparison = 0;
    if (compareA > compareB) {
        comparison = 1;
    } else if (compareA < compareB) {
        comparison = -1;
    }

    return comparison;

    /*
        return (
            /[A-Za-z]/.test(a.label) - /[A-Za-z]/.test(b.label) ||
            a.label.charCodeAt(0) - b.label.charCodeAt(0)
        );
        */
};

export function updateForm(data, franchiseRef, currentFranchiseValue) {
    (data || []).map((form) => {
        form.sections.map((section) => {
            section.rows.map((row) => {
                row.fields.map((field, count) => {
                    if (field) {
                        if (field.name === 'Characters') {
                            let page = 0;
                            const increment = 20;
                            let previousSearch = '';
                            // eslint-disable-next-line no-param-reassign
                            row.fields[count] = {
                                ...field,
                                isClearable: false,
                                displayType: 'MULT',
                                dontConvertSelect: true,
                                noOptionsMessage: ({ inputValue }) =>
                                    inputValue.length ? 'No matching results' : 'Type to search',
                                loadOptions: async (inputValue) => {
                                    const franchiseValue =
                                        currentFranchiseValue ||
                                        getFormField(data, 'Franchise') ||
                                        getFormField(data, 'Franchise - O and O');
                                    const results = await searchCharacters(
                                        inputValue,
                                        previousSearch,
                                        franchiseValue
                                            ? Array.isArray(franchiseValue)
                                                ? franchiseValue.join(',')
                                                : franchiseValue
                                            : [],
                                        page
                                    );
                                    previousSearch = inputValue;
                                    page += increment;
                                    const options = results.map((result) => ({
                                        value: result.id,
                                        label: result.name,
                                    }));

                                    return {
                                        options: (options || []).sort(properSort),
                                        hasMore: results.length === 20,
                                    };
                                },
                            };
                        }

                        if (field.name === 'Keywords') {
                            // eslint-disable-next-line no-param-reassign
                            row.fields[count] = {
                                ...field,
                                isClearable: false,
                                displayType: 'MULT',
                                dontConvertSelect: true,
                                noOptionsMessage: ({ inputValue }) =>
                                    inputValue.length ? 'No matching results' : 'Type to search',
                                loadOptions: async (inputValue) => {
                                    const results = await searchKeywords(inputValue);

                                    const options = results.map((result) => ({
                                        value: result.id,
                                        label: result.name,
                                    }));

                                    return { options: (options || []).sort(properSort) };
                                },
                            };
                        }

                        if (field.name === 'Collection Main Title - DMC') {
                            // eslint-disable-next-line no-param-reassign
                            row.fields[count] = {
                                ...field,
                                isClearable: false,
                                displayType: 'SLCT',
                                dontConvertSelect: true,
                                noOptionsMessage: ({ inputValue }) =>
                                    inputValue.length ? 'No matching results' : 'Type to search',
                                loadOptions: async (inputValue) => {
                                    const results = await searchTitles(inputValue);

                                    const options = results.map((result) => ({
                                        value: result.id,
                                        label: result.name,
                                    }));

                                    return (options || []).sort(properSort);
                                },
                            };
                        }
                    }

                    return null;
                });

                return null;
            });

            return null;
        });

        return null;
    });

    return data;
}
