import React from 'react';
import {
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import {
    sendToPLM,
    getPLMDocuments
} from '../../utils/api';
import { toast } from 'react-toastify';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import ThinkingButton from '../thinkingButton';

class PLMDocumentPanel extends React.Component {

    state = {
        checked: [],
        expanded: [],
    };

    submitting = false;

    nodes = [];
    lastSent = "";
    projectID;
    requestID;
    productID;
    fieldsByKey;

    constructor(props) {
        super(props);
        this.projectID = props.projectID;
        this.loadDocuments(props.data);
        this.initProjectFields(props.project.forms);
        console.log("***PROPS: " + JSON.stringify(props));
    }

    initProjectFields(forms) {
        if (forms.length) {
            forms.map((form) => {
                form.sections.map((section) => {
                    section.rows.map((row) => {
                        row.fields.map((field) => {

                            if (field.name === 'Request ID') {
                                this.requestID = field.value;
                            } else if (field.name === 'PLM Product ID') {
                                this.productID = field.value;
                            }
                            return null;
                        });
                        return null;
                    });
                    return null;
                });
                return null;
            });
        }
    }

    loadDocuments(data) {
        if (data.plmFolders) {
            this.nodes = this.prep(data.plmFolders);
            this.lastSent = data.lastSentDate;
        }
    }

    constructPLMBody() {
        let plm = {};
        plm.productID = this.productID;
        plm.wfProjectId = this.projectID;
        plm.requestID = this.requestID;

        plm.data = this.constructData();
        return plm;
    }

    constructData() {
        return this.state.checked.map(value => {
            return {
                docID: value.split(':')[0],
                imageType: value.split(':')[1],
            }
        });
    }

    prep = (nodes) => {
        nodes.forEach(function (node) {
            node.label = <span style={{ fontSize: 13 }}>{node.label}</span>
            node.children.forEach(function (child) {
                child.value = child.wfID + ':' + child.imgType;
                var label = child.label.length > 15 ? child.label.substring(0, 15) + '...' : child.label;
                if (child.status === 'Sent') {
                    child.label = <span title={child.label}><span style={{ fontSize: 13 }}>{label}  </span><span><i style={{ color: 'green' }} class="fa fa-check" aria-hidden="true"></i></span></span >
                } else if (child.status === 'Error') {
                    child.label = <span title={child.label} ><span style={{ fontSize: 13 }}>{label}  </span><span><i style={{ color: ' red' }} class="fa fa-times" aria-hidden="true" ></i></span></span>
                } else if (child.status === 'Pending') {
                    child.label = <span title={child.label} ><span style={{ fontSize: 13 }}>{label}  </span><span><i style={{ color: 'black' }} className="fa fa-arrow-right" aria-hidden="true"></i></span></span>
                }
                else {
                    child.label = <span title={child.label} style={{ fontSize: 13 }}>{label}  </span>;
                }
            });
        });


        return nodes;
    }

    render() {
        return (
            <Card className="shadow-sm" style={{ width: '300px' }}>
                <CardBody>
                    <CardTitle className="text-primary mb-2">
                        PLM
                    </CardTitle>
                    <hr />

                    < div style={{ fontSize: 13 }
                    }>
                        <CheckboxTree

                            nodes={this.nodes}
                            checked={this.state.checked}
                            expanded={this.state.expanded}
                            onCheck={checked => {
                                this.setState({ checked });
                            }}
                            onExpand={expanded => this.setState({ expanded })}
                        />
                        <hr />
                        {this.lastSent ? (
                            <span>Last sent on {this.lastSent}</span>
                        ) : null}
                        < br />
                        <ThinkingButton
                            style={{ fontSize: '0.75rem' }}
                            outline
                            thinking={this.submitting}
                            size="sm"
                            color="primary"
                            className="mr-2 mt-2"
                            onClick={async () => {
                                if (this.state.checked.length < 1) {
                                    toast.warn('Please select at least one document');
                                    return;
                                }
                                this.submitting = true;
                                this.setState(this.state);
                                await sendToPLM(this.constructPLMBody());
                                toast.success('Documents sent to PLM');
                                this.loadDocuments(await getPLMDocuments(this.projectID));
                                //What if errors?
                                this.submitting = false;
                                this.state.checked = []
                                this.setState(this.state);
                            }}


                        >
                            Send to PLM
                        </ThinkingButton>
                    </div>
                </CardBody>
            </Card >


        );
    };
}
export default PLMDocumentPanel;
