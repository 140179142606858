import React, { useRef, useState } from 'react';
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import ReadMoreReact from 'read-more-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';
import DateTime from './datetime';
import FileUpload from './fileUpload';
import StyleGuideButton from './styleGuideButton';
import GenerateDocumentsButton from './generateDocumentsButton';

const FormField = (props) => {
    const {
        field,
        onChange,
        fieldsByKey,
        readOnly,
        showIsRequired,
        shouldDisplay,
        dropdownOptions,
        setDefaultValues,
        generateDocuments,
        submitForm,
    } = props;

    const fieldRef = useRef();
    let optionMap;

    const [modal, setModal] = useState(false);
    const [selectInput, setSelectInput] = useState('');

    const toggle = () => {
        setModal(!modal);
    };

    const selectStyles = { menu: (styles) => ({ ...styles, zIndex: 999 }) };

    const buidlOptionMap = () => {
        if (!optionMap && Array.isArray(dropdownOptions)) {
            optionMap = new Map();
            dropdownOptions.forEach((term) => {
                optionMap.set(term.name, term.options);
            });
        }
    };

    const makeOptions = (name, options) => {
        if (options.length === 0) {
            buidlOptionMap();
        }
        if (options.length === 0 && optionMap && optionMap.has(name)) {
            // eslint-disable-next-line no-param-reassign
            options = [...optionMap.get(name)];
        }

        const returnOptions = [];
        options.map((term) => {
            if (!term.isHidden && term.value && term.label) {
                returnOptions.push({ value: term.value, label: term.label });
            }

            return null;
        });

        return returnOptions;
    };

    const checkRequired = () => {
        let className = '';
        const { value, isDirty } = fieldsByKey[field.name] || { value: '' };
        if (
            field.isRequired &&
            isDirty &&
            ((Array.isArray(value) && value.length === 0) || !value) &&
            showIsRequired
        ) {
            className = ' is-invalid';
        }

        return className;
    };

    const thisOnChange = (newValue) => {
        const updatedField = field;
        updatedField.value = newValue;
        updatedField.isDirty = true;
        onChange(updatedField);
    };

    const convertSelects = (values) => {
        let returnValues = Array.isArray(values)
            ? values
            : typeof value === 'string' && values.indexOf(',') > -1
            ? values.split(',')
            : [values];

        returnValues = returnValues.map((value) => {
            if (value && typeof value === 'object' && value.value) {
                return value.value;
            }

            return value;
        });

        if (returnValues && returnValues[0] === null) {
            returnValues = '';
        }

        return returnValues;
    };

    const makeSelects = (value, options) => {
        const values = Array.isArray(value)
            ? value
            : typeof value === 'string' && value.indexOf(',') > -1
            ? value.split(',')
            : [value];
        const returnValues = [];
        const labelsById = {};
        options.map((option) => {
            labelsById[option.value] = option.label;

            return null;
        });

        (values || []).map((thisValue) => {
            if (thisValue) {
                returnValues.push({
                    label: labelsById[thisValue] || `Missing Label (${thisValue})`,
                    value: thisValue,
                });
            }

            return null;
        });

        // returnValues.sort(properSort);

        return returnValues;
    };
    const fieldValue = fieldsByKey[field.name] ? fieldsByKey[field.name].value : '';

    let SelectType =
        field.displayType === 'SLCT' || field.displayType === 'MULT'
            ? field.loadOptions
                ? AsyncSelect
                : Select
            : null;

    if (field.name === 'Characters' || field.name === 'Keywords') {
        SelectType = AsyncPaginate;
    }

    const getValue = (name, val, options) => {
        if (options.length === 0) {
            buidlOptionMap();
        }
        if (options.length === 0 && optionMap && optionMap.has(name)) {
            // eslint-disable-next-line no-param-reassign
            options = [...optionMap.get(name)];
        }
        let returnVal = [];

        if (val) {
            returnVal = makeSelects(val, options);
            if (Array.isArray(val) && val[0] && val[0].label && val[0].value) {
                returnVal = val;
            }
            if (val.value && val.label) {
                returnVal = val;
            }
        }

        return Array.isArray(returnVal) ? returnVal : [returnVal];
    };

    const Option = (propOptions) => (
        <div>
            <components.Option {...propOptions}>
                <input type="checkbox" checked={propOptions.isSelected} onChange={() => null} />{' '}
                <label>{propOptions.label}</label>
            </components.Option>
        </div>
    );

    const makeSuggestions = (suggestions, selected) => {
        const alreadySelected = (selected || []).map((option) => option.value.toString());

        return (
            <div className="select-suggestions" style={{ fontSize: '.85rem' }}>
                <Label className="mt-2 mb-0">Suggestions:</Label>
                <br />
                {suggestions.map((suggestion) =>
                    alreadySelected.indexOf(suggestion.value) === -1 ? (
                        <Button
                            style={{ fontSize: '0.75rem' }}
                            outline
                            size="sm"
                            color="primary"
                            className="mr-2 mt-2"
                            onClick={() => {
                                thisOnChange(
                                    (getValue(field.name, fieldValue, field.options) || []).concat([
                                        {
                                            value: suggestion.value,
                                            label: suggestion.label,
                                        },
                                    ])
                                );
                            }}
                        >
                            {suggestion.label} - <em>{suggestion.score}%</em>
                        </Button>
                    ) : null
                )}
            </div>
        );
    };

    return (field.displayLogicRules || []).length === 0 ||
        shouldDisplay(field.displayLogicRules, fieldsByKey) ? (
        <>
            <FormGroup>
                {field.displayType !== 'DTXT' ? (
                    <Label className="mb-0">
                        {field.name}
                        {field.isRequired ? <span className="text-danger ml-1">*</span> : ''}
                    </Label>
                ) : null}
                {field.displayType !== 'DTXT' && field.description ? (
                    <>
                        <FormText>
                            <ReadMoreReact readMoreText="…more" text={field.description} />
                        </FormText>
                    </>
                ) : null}
                {readOnly ||
                field.readOnly ||
                (field.isEditable !== undefined && field.isEditable === false) ||
                field.displayType === 'CALC' ? (
                    <div>
                        {fieldValue ? (
                            Array.isArray(fieldValue) ? (
                                fieldValue.join(', ')
                            ) : (
                                fieldValue
                            )
                        ) : (
                            <em>{fieldValue}</em>
                        )}
                    </div>
                ) : field.dataType === 'FILE' ? (
                    <FileUpload
                        data-form-id={`file:${field.id}`}
                        onChange={thisOnChange}
                        className={`input-file ${checkRequired()}`}
                    />
                ) : field.displayType === 'DTXT' ? (
                    <p>{field.description}</p>
                ) : field.dataType === 'DTTM' || field.dataType === 'DATE' ? (
                    <DateTime
                        data-form-id={`input:${field.id}`}
                        value={field.value}
                        onChange={thisOnChange}
                        isClearable={!field.isRequired}
                        className={`input-datetime ${checkRequired()}`}
                        dateFormat={field.dataType}
                    />
                ) : field.displayType === 'SLCT' || field.displayType === 'MULT' ? (
                    <>
                        <SelectType
                            ref={fieldRef}
                            data-form-id={`input:${field.id}`}
                            onChange={(selected) => {
                                thisOnChange(
                                    !field.dontConvertSelect ? convertSelects(selected) : selected
                                );
                            }}
                            defaultOptions={field.defaultOptions || false}
                            isClearable={
                                field.isClearable !== undefined
                                    ? field.isClearable
                                    : !field.isRequired
                            }
                            noOptionsMessage={field.noOptionsMessage || (() => 'No options')}
                            className={`input-select ${checkRequired()}`}
                            classNamePrefix="react-select"
                            name={field.name}
                            isMulti={field.displayType === 'MULT'}
                            closeMenuOnSelect={false}
                            onSelectResetsInput={false}
                            hideSelectedOptions={false}
                            blurInputOnSelect={false}
                            cacheOptions
                            styles={selectStyles}
                            options={makeOptions(field.name, field.options)}
                            inputValue={selectInput}
                            onInputChange={(value, action) => {
                                if (action.action === 'input-change') setSelectInput(value);
                            }}
                            components={{
                                Option,
                            }}
                            value={getValue(field.name, fieldValue, field.options) || []}
                            placeholder={field.placeholder}
                            loadOptions={
                                field.loadOptions
                                    ? async (inputValue) => field.loadOptions(inputValue, fieldRef)
                                    : null
                            }
                            onFocus={field.onFocus || null}
                            onMenuOpen={() => {
                                if (field.onMenuOpen) {
                                    field.onMenuOpen(fieldRef);
                                }
                            }}
                        />
                        {field.suggestions && field.suggestions.length
                            ? makeSuggestions(
                                  field.suggestions,
                                  getValue(field.name, fieldValue, field.options)
                              )
                            : null}
                    </>
                ) : field.displayType === 'CHCK' || field.displayType === 'RDIO' ? (
                    <Row className="mx-1">
                        {field.options.map((option) =>
                            !option.isHidden ? (
                                <Col key={option.value} className="d-flex mb-2" md="auto">
                                    <Label
                                        className={`mr-4 mb-0 d-flex align-self-start ${checkRequired()}`}
                                    >
                                        <Input
                                            data-form-id={`input:${field.id}:${
                                                field.displayType === 'CHCK'
                                                    ? option.label
                                                    : field.name
                                            }`}
                                            className={`mr-2 align-self-center ${checkRequired()}`}
                                            type={
                                                field.displayType === 'CHCK' ? 'checkbox' : 'radio'
                                            }
                                            name={
                                                field.displayType === 'CHCK'
                                                    ? option.label
                                                    : field.name
                                            }
                                            defaultValue={option.value}
                                            onChange={(e) => {
                                                const { target } = e;
                                                if (field.displayType === 'CHCK') {
                                                    const existingValues = Array.isArray(
                                                        field.value
                                                    )
                                                        ? field.value
                                                        : typeof field.value === 'string'
                                                        ? [field.value]
                                                        : [];
                                                    if (target.checked) {
                                                        existingValues.push(target.value);
                                                    } else {
                                                        existingValues.splice(
                                                            existingValues.indexOf(target.value),
                                                            1
                                                        );
                                                    }
                                                    thisOnChange(existingValues);
                                                } else {
                                                    thisOnChange(target.value);
                                                }
                                            }}
                                            checked={
                                                field.displayType === 'CHCK'
                                                    ? (field.value || []).indexOf(option.value) > -1
                                                    : field.value === option.value
                                            }
                                        />
                                        {option.label}
                                    </Label>
                                </Col>
                            ) : null
                        )}

                        {field.showStyleGuideButton &&
                        fieldsByKey['Style Guide Size'] &&
                        fieldsByKey['Style Guide Size'].value ? (
                            <div>
                                <StyleGuideButton toggle={toggle} />
                            </div>
                        ) : null}

                        {field.name === 'Style Guide Size' &&
                        fieldsByKey['Style Guide Size'] &&
                        fieldsByKey['Style Guide Size'].showError ? (
                            <div>
                                &nbsp;&nbsp;&nbsp;There are no default values for this style guide
                                type and size
                            </div>
                        ) : null}

                        <div>
                            <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader>Populate Style Guide Fields?</ModalHeader>
                                <ModalBody>
                                    This will populate the Design Element fields and quantities with
                                    default values, per the selected Style Guide Size. This will
                                    overwrite previous selections. Are you sure you want to proceed?
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        onClick={async () => {
                                            await setDefaultValues(fieldsByKey, onChange);
                                            toggle();
                                        }}
                                    >
                                        Yes
                                    </Button>{' '}
                                    <Button color="secondary" onClick={toggle}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </Row>
                ) : (
                    <Row className="mx-1">
                        <Input
                            data-form-id={`input:${field.id}`}
                            style={{ width: field.displaySize ? 'auto' : null }}
                            size={
                                field.displayType !== 'TXTA' && field.displaySize
                                    ? field.displaySize.toString()
                                    : null
                            }
                            cols={
                                field.displayType === 'TXTA' && field.displaySize
                                    ? field.displaySize.toString()
                                    : null
                            }
                            onChange={(e) => {
                                thisOnChange(e.target.value);
                            }}
                            onBlur={(e) => thisOnChange(e.target.value)}
                            type={
                                field.displayType === 'TXTA'
                                    ? 'textarea'
                                    : field.dataType === 'NMBR' || field.dataType === 'CURC'
                                    ? 'number'
                                    : 'text'
                            }
                            onKeyDown={(e) => {
                                if (field.dataType === 'CURC' && ['e', '+'].includes(e.key)) {
                                    e.preventDefault();

                                    return false;
                                }

                                return true;
                            }}
                            name={field.name}
                            value={
                                fieldsByKey[field.name]
                                    ? fieldsByKey[field.name].value
                                    : field.value
                            }
                            className={checkRequired()}
                        />

                        {field.showGenerateDocumentButton &&
                        fieldsByKey['Number of Design Sets'] &&
                        fieldsByKey['Number of Design Sets'].value ? (
                            // eslint-disable-next-line no-constant-condition
                            true ? (
                                <div className="pl-2">
                                    <GenerateDocumentsButton
                                        fieldsByKey={fieldsByKey}
                                        messageText={`Last generated: ${
                                            fieldsByKey['Date of Document Generation'] &&
                                            fieldsByKey['Date of Document Generation'].value
                                                ? moment(
                                                      fieldsByKey[
                                                          'Date of Document Generation'
                                                      ].value.substring(0, 10)
                                                  ).format('MMM DD, YYYY')
                                                : ''
                                        }`}
                                        generateDocuments={generateDocuments}
                                        submitForm={submitForm}
                                    />
                                </div>
                            ) : null
                        ) : null}
                    </Row>
                )}
            </FormGroup>
        </>
    ) : null;
};

FormField.propTypes = {
    field: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onChange: PropTypes.func,
    shouldDisplay: PropTypes.func,
    generateDocuments: PropTypes.func,
    setDefaultValues: PropTypes.func,
    submitForm: PropTypes.func,
    fieldsByKey: PropTypes.objectOf(PropTypes.any),
    readOnly: PropTypes.bool,
    showIsRequired: PropTypes.bool,
    dropdownOptions: PropTypes.objectOf(PropTypes.any),
};
FormField.defaultProps = {
    onChange: () => {},
    shouldDisplay: () => {},
    setDefaultValues: () => {},
    generateDocuments: () => {},
    submitForm: () => {},
    fieldsByKey: {},
    readOnly: true,
    showIsRequired: true,
    dropdownOptions: {},
};

export default FormField;
