import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';

const ThinkingOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75) url(/img/loading.svg) no-repeat center/contain;
    background-size: auto 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ThinkingButton = (props) => {
    const { children, thinking, ...buttonProps } = props;

    return (
        <Button {...buttonProps}>
            {thinking ? <ThinkingOverlay /> : null}
            {children}
        </Button>
    );
};

ThinkingButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    thinking: PropTypes.bool,
};

ThinkingButton.defaultProps = {
    children: undefined,
    thinking: false,
};

export default ThinkingButton;
