/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row, Col, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { Loading } from '../../pages';
import { updateDocument, downloadDocument } from '../../utils/api';
import Form from './form';
import * as CharacterSearch from './characterSearch';

const Document = (props) => {
    const { document, franchiseRef, franchiseValue, termOptions } = props;

    const [saving, setSaving] = useState(false);
    const [documentForms, setDocumentForms] = useState();
    const optionMap = new Map();

    const setDropDownOptions = () => {
        termOptions.forEach((term) => {
            if (term.name === 'Art Style - DMC') {
                optionMap.set(term.name, term.options);
            } else if (term.name === 'Content Type - DMC') {
                optionMap.set(term.name, term.options);
            } else if (term.name === 'Holiday - DMC') {
                optionMap.set(term.name, term.options);
            } else if (term.name === 'Design Element - DMC') {
                optionMap.set(term.name, term.options);
            } else if (term.name === 'Language - DMC') {
                optionMap.set(term.name, term.options);
            }
        });
    };

    useEffect(() => {
        if (optionMap.size === 0) {
            setDropDownOptions();
        }
        const newSection = {
            description: '',
            displayLogicRules: [],
            id: 'hardCodedForm',
            name: '',
            rows: [
                {
                    fields: [null],
                },
                {
                    fields: [null, null],
                },
                {
                    fields: [null, [null, null]],
                },
                {
                    fields: [null],
                },
                {
                    fields: [null],
                },
                {
                    fields: [null],
                },
                {
                    fields: [null],
                },
            ],
        };
        if (document.forms && document.forms.length > 0) {
            const emptiedForms = [];

            JSON.parse(JSON.stringify(document.forms)).map((form) => {
                const sections = [];
                form.sections.map((section) => {
                    const rows = [];
                    section.rows.map((row) => {
                        const fields = [];
                        row.fields.map((field) => {
                            let found = false;
                            if (field) {
                                if (field.name === 'DMC Name') {
                                    if (!field.value || field.value !== document.name) {
                                        field.value = document.name.replace(/ /g, '_');
                                    }
                                    newSection.rows[0].fields[0] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Art Style - DMC') {
                                    field.options = [...optionMap.get(field.name)];
                                    newSection.rows[1].fields[0] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Content Type - DMC') {
                                    field.options = [...optionMap.get(field.name)];
                                    newSection.rows[1].fields[1] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Characters') {
                                    newSection.rows[2].fields[0] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Holiday - DMC') {
                                    field.options = [...optionMap.get(field.name)];
                                    newSection.rows[2].fields[1] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Language - DMC') {
                                    field.options = [...optionMap.get(field.name)];
                                    newSection.rows[3].fields[0] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Keywords') {
                                    newSection.rows[4].fields[0] = { ...field };
                                    found = true;
                                }

                                if (field.name === 'Design Element - DMC') {
                                    field.options = [...optionMap.get(field.name)];
                                    newSection.rows[5].fields[0] = { ...field };
                                }

                                if (field.name === 'Editorial') {
                                    newSection.rows[6].fields[0] = { ...field };
                                }
                            }

                            if (!found) {
                                fields.push(field);
                            }

                            return null;
                        });

                        if (fields.length) {
                            rows.push({
                                fields,
                            });
                        }

                        return null;
                    });

                    if (rows.length) {
                        sections.push({
                            description: section.description,
                            displayLogicRules: section.displayLogicRules,
                            id: section.id,
                            name: section.name,
                            rows,
                        });
                    }

                    return null;
                });

                if (sections.length) {
                    emptiedForms.push({
                        name: form.name,
                        description: form.description,
                        sections,
                    });
                }

                return null;
            });

            emptiedForms.unshift({
                name: '',
                description: '',
                sections: [newSection],
            });

            const updatedForms = CharacterSearch.updateForm(
                emptiedForms,
                franchiseRef,
                franchiseValue
            );

            setDocumentForms(updatedForms);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Loading loading={saving} childrenVisible>
            <Row>
                <Col xs="auto" className="text-center" style={{ width: '233px' }}>
                    {document.thumbnailOriginal ? (
                        <div
                            style={{
                                width: '200px',
                                height: '200px',
                                background: `#dee2e6 url(${document.thumbnailOriginal}) no-repeat center / contain`,
                                border: 'solid 1px #dee2e6',
                                margin: '20px auto 0 auto',
                            }}
                        />
                    ) : null}
                    <Label className="d-block my-3">{document.name}</Label>
                    <Button
                        size="sm"
                        color="link"
                        onClick={async () => {
                            const downloadUrl = await downloadDocument(document.id);
                            window.open(downloadUrl);
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'download']} className="mr-1" />
                        Download
                    </Button>
                </Col>
                <Col>
                    {documentForms && documentForms.length ? (
                        <Form
                            forms={documentForms}
                            collapseForms
                            submitByDefault
                            submitForm={async (formFields) => {
                                setSaving(true);
                                const formValues = {
                                    formFieldValues: formFields,
                                };

                                const saveDocumentResponse = await updateDocument(
                                    document.id,
                                    formValues
                                );
                                if (saveDocumentResponse) {
                                    toast.success('Document saved.');
                                    // onUpdate(); // disabling refresh on every save
                                } else {
                                    // toast error is handled in the API, nothing to do here
                                }
                                setSaving(false);

                                return true;
                            }}
                        />
                    ) : null}
                </Col>
            </Row>
        </Loading>
    );
};

Document.propTypes = {
    document: PropTypes.objectOf(PropTypes.any).isRequired,
    franchiseRef: PropTypes.objectOf(PropTypes.any).isRequired,
    franchiseValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object])
        .isRequired,
    termOptions: PropTypes.objectOf(PropTypes.any),
};

Document.defaultProps = {
    termOptions: {},
};

export default Document;
