import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const Thanks = (props) => {
    const { history } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'thanks',
                }}
                title="Thanks"
            />
            <Container>
                <Row>
                    <Col md="12">
                        <h1 className="text-center mb-5">
                            Thank you for submitting this&nbsp;request
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md="2" />
                    <Col md="8" className="text-center">
                        <Button color="primary" className="mt-5" onClick={() => history.push('/')}>
                            Create Another Request
                        </Button>
                    </Col>
                    <Col md="2" />
                </Row>
            </Container>
        </>
    );
};

Thanks.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

export default Thanks;
