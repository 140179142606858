import React from 'react';
import { Col, FormText, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormField } from './index';

const Fieldset = (props) => {
    const {
        section,
        onChange,
        fieldsByKey,
        readOnly,
        showIsRequired,
        shouldDisplay,
        dropdownOptions,
        setDefaultValues,
        generateDocuments,
        submitForm,
    } = props;

    return (
        <fieldset>
            <h5 className="mt-3 form-header">{(section.name || '').toUpperCase()}</h5>
            {section.description ? <FormText>{section.description}</FormText> : null}
            {section.rows.map((row, rowCount) => (
                <Row
                    form
                    key={`formgroup:${row.fields.map(
                        (field, fieldCount) =>
                            `${
                                field && field.name && field.name !== ''
                                    ? field.name
                                    : `${rowCount.toString()}:${fieldCount.toString()}`
                            }:`
                    )}`}
                >
                    {row.fields.map((field) =>
                        field ? (
                            <Col key={`filedset:${field.id || field.ID}`} className="pr-0">
                                {(Array.isArray(field) ? field : [field]).map((subField) => (
                                    <FormField
                                        field={subField}
                                        key={`${subField.id}`}
                                        onChange={onChange}
                                        fieldsByKey={fieldsByKey}
                                        readOnly={readOnly}
                                        showIsRequired={showIsRequired}
                                        shouldDisplay={shouldDisplay}
                                        dropdownOptions={dropdownOptions}
                                        setDefaultValues={setDefaultValues}
                                        generateDocuments={generateDocuments}
                                        submitForm={submitForm}
                                    />
                                ))}
                            </Col>
                        ) : (
                            <Col className="pr-0" />
                        )
                    )}
                </Row>
            ))}
        </fieldset>
    );
};
Fieldset.propTypes = {
    onChange: PropTypes.func,
    shouldDisplay: PropTypes.func,
    section: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    fieldsByKey: PropTypes.objectOf(PropTypes.any),
    readOnly: PropTypes.bool,
    showIsRequired: PropTypes.bool,
    dropdownOptions: PropTypes.objectOf(PropTypes.any),
    submitForm: PropTypes.func,
    setDefaultValues: PropTypes.func,
    generateDocuments: PropTypes.func,
};
Fieldset.defaultProps = {
    onChange: () => {},
    shouldDisplay: () => {},
    section: {
        name: '',
        rows: [],
    },
    fieldsByKey: {},
    readOnly: true,
    showIsRequired: true,
    dropdownOptions: {},
    submitForm: () => {},
    setDefaultValues: () => {},
    generateDocuments: () => {},
};
export default Fieldset;
