import { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollTop = (props) => {
    const prevLocation = useRef();
    useEffect(() => {
        prevLocation.current = props.location.pathname;
    }, [props.location.pathname]);

    if (props.location.pathname !== prevLocation.current) {
        window.scrollTo(0, 0);
    }

    return props.children || null;
};

export default withRouter(ScrollTop);
