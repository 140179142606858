/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Collapse,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Stickyfill from 'stickyfilljs';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { Loading } from '.';
import {
    generateDocuments,
    getDmcData,
    getFolderStructure,
    getProject,
    sendToDmc,
    updateProject,
    getPLMDocuments
} from '../utils/api';
import Form from '../components/forms/form';
import ThinkingButton from '../components/thinkingButton';
import Document from '../components/forms/document';
import * as CharacterSearch from '../components/forms/characterSearch';
import * as GenerateDocuments from '../components/forms/generateDocuments';
import { PLMDocumentPanel } from '../components/plm';

const Project = (props) => {
    const { match, history } = props;
    const { projectId, activeTab: activeTabProp } = match.params;

    const [project, setProject] = useState();
    const [dmc, setDmc] = useState();
    const [documents, setDocuments] = useState();
    const [plmDocuments, setPlmDocuments] = useState();
    const [artStyleOptions, setArtStyleOptions] = useState();
    const [contentTypeOptions, setContentTypeOptions] = useState();
    const [holidayOptions, setHolidayOptions] = useState();
    const [designElementOptions, setDesignElementOptions] = useState();
    const [activeTab, setActiveTab] = useState(activeTabProp || 'overview');
    const [loading, setLoading] = useState(true);
    const [pageNotFound, setPageNotFound] = useState();
    const [modal, setModal] = useState(false);
    const [updatingDmc, setUpdatingDmc] = useState(false);
    const franchiseRef = useRef();
    const columnHeaders = [
        'doc-designelement-col',
        'doc-dmcname-col',
        'doc-artstyle-col',
        'doc-contenttype-col',
        'doc-characters-col',
        'doc-keywords-col',
    ];

    const toggle = () => setModal(!modal);

    const fetchProject = async () => {
        const projectData = await getProject(projectId);
        const dmcData = await getDmcData(projectId);
        const documentsData = await getFolderStructure(projectId);
        const plmDocuments = await getPLMDocuments(projectId);

        if (!projectData) {
            setPageNotFound(true);
        } else {
            setDropDownOptions(documentsData.options);
            projectData.forms = CharacterSearch.updateForm(projectData.forms, franchiseRef);
            projectData.forms = GenerateDocuments.updateForm(projectData.forms);

            setProject(projectData);
            setDmc(dmcData);
            setDocuments(documentsData);
            setPlmDocuments(plmDocuments);
        }
        setLoading(false);
    };

    const setDropDownOptions = (optionList) => {
        optionList.forEach((term) => {
            if (term.name === 'Art Style - DMC') {
                setArtStyleOptions(term.options);
            } else if (term.name === 'Content Type - DMC') {
                setContentTypeOptions(term.options);
            } else if (term.name === 'Holiday - DMC') {
                setHolidayOptions(term.options);
            } else if (term.name === 'Design Element - DMC') {
                setDesignElementOptions(term.options);
            }
        });
    };

    useEffect(() => {
        window.setTimeout(() => {
            const docHeader = document.getElementById('doc-header');
            if (docHeader) {
                Stickyfill.add(docHeader);
            }
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]);

    useEffect(() => {
        fetchProject();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);


    useEffect(() => {
        columnHeaders.forEach((header, index) => {
            const headerDiv = document.getElementById(header);
            const classname = header.slice(0, -4);
            let mPos;
            if (headerDiv != null) {
                headerDiv.addEventListener(
                    'mousedown',
                    (e) => {
                        mPos = e.clientX;
                        document.addEventListener('mousemove', resize, false);
                    },
                    false
                );

                document.addEventListener(
                    'mouseup',
                    () => {
                        document.removeEventListener('mousemove', resize, false);
                    },
                    false
                );

                const resize = (e) => {
                    const dx = e.clientX - mPos;
                    mPos = e.clientX;
                    const colElements = document.getElementsByClassName(classname);
                    const adjColIndex1 =
                        index > 5 ? index - 1 : index === 0 ? index + 1 : index - 1;
                    const adjColIndex2 =
                        index > 5
                            ? index - 2
                            : index === 0
                                ? index + 2
                                : index === 1
                                    ? index + 1
                                    : index - 2;
                    const adjCol1 = columnHeaders[adjColIndex1].slice(0, -4);
                    const adjCol2 = columnHeaders[adjColIndex2].slice(0, -4);
                    colElements.forEach((colElement) => {
                        resetWidth(colElement, dx);
                    });

                    const adjColElmts1 = document.getElementsByClassName(adjCol1);
                    adjColElmts1.forEach((colElement) => {
                        resetWidth(colElement, dx / 2);
                    });
                    const adjColElmts2 = document.getElementsByClassName(adjCol2);
                    adjColElmts2.forEach((colElement) => {
                        resetWidth(colElement, dx / 2);
                    });
                };
            }
        });
    });

    const resetWidth = (colElement, dx) => {
        // eslint-disable-next-line no-param-reassign
        colElement.style.minWidth = `${parseInt(getComputedStyle(colElement, '').width, 10) + dx
            }px`;
    };

    const localizeDate = (date) =>
        moment.utc(date).local().tz(moment.tz.guess()).format('MMM D, YYYY h:mma z');

    const drawDocuments = (data, level) => (
        <>
            {(data.folders || []).map((folder) => (
                <RowFolder key={folder.id} folder={folder} level={level} />
            ))}
            {(data.documents || []).map((document) => (
                <RowDocument key={document.id} document={document} level={level} />
            ))}
        </>
    );

    const RowFolder = (folderObj) => {
        const { folder, level } = folderObj;
        const [folderId, setFolderId] = useState('');
        const toggleFolder = (selectedFolder) => {
            if (folderId === selectedFolder) {
                setFolderId('');
            } else {
                setFolderId(selectedFolder);
            }
        };

        return (
            <React.Fragment key={folder.id}>
                <Row
                    className={`doc-row doc-folder flex-nowrap clickable${folder.id === folderId ? ' opened' : ''
                        }`}
                    onClick={() => toggleFolder(folder.id)}
                >
                    <Col
                        className="doc-name d-flex align-items-center"
                        style={{ marginLeft: `${level * 50}px`, minWidth: `${300 - level * 50}px` }}
                    >
                        <FontAwesomeIcon icon={['fas', 'folder']} size="2x" className="mr-3" />
                        {folder.name}
                    </Col>
                </Row>
                <Collapse isOpen={folder.id === folderId}>
                    {drawDocuments(folder, level + 1)}
                </Collapse>
            </React.Fragment>
        );
    };

    const RowDocument = (documentObj) => {
        const { document, level } = documentObj;
        const colValues = {
            dmcName: null,
            designElement: null,
            artStyle: null,
            contentType: null,
            characters: null,
            keywords: null,
            holiday: null,
            language: null,
        };

        const [openedDocId, setOpenedDocId] = useState();

        // function properSort(a, b) {
        //     const compareA =
        //         parseFloat(a.label).toString().length === a.label.length
        //             ? parseFloat(a.label)
        //             : a.label.toUpperCase();
        //     const compareB =
        //         parseFloat(b.label).toString().length === b.label.length
        //             ? parseFloat(b.label)
        //             : b.label.toUpperCase();

        //     let comparison = 0;
        //     if (compareA > compareB) {
        //         comparison = 1;
        //     } else if (compareA < compareB) {
        //         comparison = -1;
        //     }

        //     return comparison;

        //     /*
        //     return (
        //         /[A-Za-z]/.test(a.label) - /[A-Za-z]/.test(b.label) ||
        //         a.label.charCodeAt(0) - b.label.charCodeAt(0)
        //     );
        //     */
        // }

        const getValues = (value, options) => {
            const values = Array.isArray(value)
                ? value
                : typeof value === 'string' && value.indexOf(',') > -1
                    ? value.split(',')
                    : [value];
            const returnValues = [];
            const labelsById = {};
            options.map((option) => {
                labelsById[option.value] = option.label;

                return null;
            });

            (values || []).map((thisValue) => {
                if (thisValue) {
                    returnValues.push({
                        label: labelsById[thisValue] || `Missing Label (${thisValue})`,
                        value: thisValue,
                    });
                }

                return null;
            });

            // returnValues.sort(properSort);

            return returnValues.length
                ? returnValues.map((option) => option.label).join(', ')
                : null;
        };

        (document.forms || []).map((form) => {
            form.sections.map((section) => {
                section.rows.map((row) => {
                    row.fields.map((field) => {
                        if (field) {
                            if (
                                field.name === 'Design Element - DMC' &&
                                field.value !== null &&
                                designElementOptions !== null
                            ) {
                                colValues.designElement = {
                                    value: getValues(field.value, designElementOptions),
                                    isRequired: true,
                                };
                            }

                            if (field.name === 'DMC Name') {
                                colValues.dmcName = {
                                    value: field.value,
                                    isRequired: field.isRequired,
                                };
                            }

                            if (
                                field.name === 'Art Style - DMC' &&
                                field.value !== null &&
                                artStyleOptions !== null
                            ) {
                                colValues.artStyle = {
                                    value: getValues(field.value, artStyleOptions),
                                    isRequired: field.isRequired,
                                };
                            }

                            if (
                                field.name === 'Content Type - DMC' &&
                                field.value !== null &&
                                contentTypeOptions !== null
                            ) {
                                colValues.contentType = {
                                    value: getValues(field.value, contentTypeOptions),
                                    isRequired: field.isRequired,
                                };
                            }

                            if (field.name === 'Characters') {
                                colValues.characters = {
                                    value: getValues(field.value, field.options),
                                    isRequired: field.isRequired,
                                };
                            }

                            if (field.name === 'Keywords') {
                                colValues.keywords = {
                                    value: getValues(field.value, field.options),
                                    isRequired: field.isRequired,
                                };
                            }

                            if (
                                field.name === 'Holiday - DMC' &&
                                field.value !== null &&
                                holidayOptions !== null
                            ) {
                                colValues.holiday = {
                                    value: getValues(field.value, holidayOptions),
                                    isRequired: field.isRequired,
                                };
                            }
                        }

                        return null;
                    });

                    return null;
                });

                return null;
            });

            return null;
        });

        const displayColumn = (colField) => {
            if (colField) {
                if (colField.value) {
                    return colField.value;
                }

                if (colField.isRequired) {
                    return (
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                            className="icon-warning text-warning"
                        />
                    );
                }
            }

            return '';
        };

        return (
            <React.Fragment key={document.id}>
                <Row
                    className={`flex-nowrap doc-row doc-document align-items-center clickable${document.id === openedDocId ? ' opened' : ''
                        }`}
                    onClick={() => setOpenedDocId(openedDocId === document.id ? null : document.id)}
                >
                    <Col
                        className="doc-name d-flex align-items-center col-xl-1 col-lg-1 col-md-1 col-sm-1"
                        style={{ marginLeft: `${level * 50}px`, minWidth: `${300 - level * 50}px` }}
                    >
                        {document.thumbnailSmall ? (
                            <div
                                style={{
                                    width: '45px',
                                    height: '45px',
                                    background: `#dee2e6 url(${document.thumbnailSmall}) no-repeat center / contain`,
                                    border: 'solid 1px #dee2e6',
                                    marginRight: '1rem',
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={['fas', 'file']}
                                size="2x"
                                className="mr-3"
                                style={{ marginLeft: '5px' }}
                            />
                        )}
                    </Col>
                    <Col className="doc-dmcname text-center col-xl-1 col-lg-1 col-md-1  col-sm-1">
                        {document.name}
                    </Col>
                    <Col className="doc-designelement text-center col-xl-1 col-lg-1 col-md-1 col-sm-1">
                        {displayColumn(colValues.designElement)}
                    </Col>
                    <Col className="doc-artstyle text-center col-xl-1 col-lg-1 col-md-1 col-sm-1">
                        {displayColumn(colValues.artStyle)}
                    </Col>
                    <Col className="doc-contenttype text-center col-xl-1 col-lg-1 col-md-1 col-sm-1">
                        {displayColumn(colValues.contentType)}
                    </Col>
                    <Col className="doc-characters text-center col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        {displayColumn(colValues.characters)}
                    </Col>
                    <Col className="doc-keywords text-center col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        {displayColumn(colValues.keywords)}
                    </Col>
                    <Col className="doc-holiday text-center col-xl-1 col-lg-1 col-md-1 col-sm-2">
                        {displayColumn(colValues.holiday)}
                    </Col>
                </Row>
                {document.id === openedDocId ? (
                    <Row className="doc-item flex-nowrap">
                        <Col
                            className="doc-name d-flex align-items-center"
                            style={{
                                marginLeft: `${level * 50}px`,
                                minWidth: `${300 - level * 50}px`,
                            }}
                        >
                            <Document
                                document={document}
                                franchiseRef={franchiseRef}
                                franchiseValue={
                                    CharacterSearch.getFormField(project.forms, 'Franchise') ||
                                    CharacterSearch.getFormField(
                                        project.forms,
                                        'Franchise - O and O'
                                    )
                                }
                                termOptions={documents.options}
                            />
                        </Col>
                    </Row>
                ) : null}
            </React.Fragment>
        );
    };

    const updateDmc = async () => {
        setUpdatingDmc(true);
        const response = await sendToDmc(projectId);

        if (response) {
            toast.success('Collection updated.');
            setDmc(response);
        }
        setUpdatingDmc(false);
    };

    return (
        <Loading container loading={loading} pageNotFound={pageNotFound} className="px-0">
            <Helmet
                htmlAttributes={{
                    'data-page': 'project',
                }}
                title="Project"
            />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>DMC Automation Log</ModalHeader>
                <ModalBody>
                    <pre>{dmc ? dmc.automationLog : null}</pre>
                </ModalBody>
            </Modal>
            <Row className="mt-5">
                <Col md="12">
                    {project ? (
                        <>
                            <Nav tabs className="mb-3">
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'overview' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('overview');
                                            history.push(`/project/${projectId}/overview`);
                                        }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'documents' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('documents');
                                            history.push(`/project/${projectId}/documents`);
                                        }}
                                    >
                                        Documents
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <h1>{project.name}</h1>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="overview">
                                    <Row>
                                        <Col>
                                            <Form
                                                forms={project.forms}
                                                submitButtonLabel="Update Project"
                                                submitByDefault
                                                submitForm={async (formFields) => {
                                                    const formValues = {
                                                        formFieldValues: formFields,
                                                    };

                                                    const updateProjectResult = await updateProject(
                                                        projectId,
                                                        formValues
                                                    );
                                                    if (updateProjectResult) {
                                                        toast.success('Project updated.');
                                                        updateProjectResult.forms = CharacterSearch.updateForm(
                                                            updateProjectResult.forms,
                                                            franchiseRef
                                                        );
                                                        updateProjectResult.forms = GenerateDocuments.updateForm(
                                                            updateProjectResult.forms
                                                        );
                                                        setProject(updateProjectResult);
                                                    } else {
                                                        // toast error is handled in the API, nothing to do here
                                                    }

                                                    return true;
                                                }}
                                                dropdownOptions={documents ? documents.options : {}}
                                                generateDocuments={async () =>
                                                    await generateDocuments(projectId)
                                                }
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Card className="shadow-sm" style={{ width: '300px' }}>
                                                {dmc ? (
                                                    <CardBody>
                                                        <CardTitle className="text-primary mb-5">
                                                            DMC Collection
                                                        </CardTitle>
                                                        <div className="text-center">
                                                            {!dmc ? (
                                                                'No Collection 🤷‍♂️'
                                                            ) : (
                                                                <>
                                                                    {dmc.status ? (
                                                                        <FontAwesomeIcon
                                                                            icon={[
                                                                                'fas',
                                                                                dmc.status ===
                                                                                    'Error'
                                                                                    ? 'exclamation-triangle'
                                                                                    : 'check-circle',
                                                                            ]}
                                                                            size="4x"
                                                                            className={
                                                                                dmc.status ===
                                                                                    'Error'
                                                                                    ? 'text-warning'
                                                                                    : 'text-success'
                                                                            }
                                                                        />
                                                                    ) : null}

                                                                    {dmc.dmcId ? (
                                                                        <p className="mt-3">
                                                                            {dmc.dmcId}
                                                                        </p>
                                                                    ) : null}

                                                                    {dmc.dmcUrl ? (
                                                                        <p className="mt-3">
                                                                            <a
                                                                                href={dmc.dmcUrl}
                                                                                target="new"
                                                                                style={{
                                                                                    textDecoration:
                                                                                        'none',
                                                                                }}
                                                                            >
                                                                                View{' '}
                                                                                <FontAwesomeIcon
                                                                                    icon={[
                                                                                        'fas',
                                                                                        'external-link-alt',
                                                                                    ]}
                                                                                />
                                                                            </a>
                                                                        </p>
                                                                    ) : null}

                                                                    {dmc.syncDate ? (
                                                                        <p className="mt-3">
                                                                            {localizeDate(
                                                                                dmc.syncDate
                                                                            )}
                                                                        </p>
                                                                    ) : null}

                                                                    {dmc.automationLog ? (
                                                                        <Button
                                                                            color="link"
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                setModal(true)
                                                                            }
                                                                        >
                                                                            View Logs
                                                                        </Button>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </div>
                                                        {dmc.canSend ? (
                                                            <ThinkingButton
                                                                onClick={updateDmc}
                                                                color="primary"
                                                                className="mt-4"
                                                                thinking={updatingDmc}
                                                            >
                                                                {dmc.syncDate ? 'Update' : 'Create'}{' '}
                                                                Collection
                                                            </ThinkingButton>
                                                        ) : null}
                                                    </CardBody>
                                                ) : null}
                                            </Card>
                                            <br />
                                            {plmDocuments && plmDocuments.plmFolders.length ? (
                                                <PLMDocumentPanel
                                                    data={plmDocuments}
                                                    projectID={projectId}
                                                    project={project}
                                                />) : null}

                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tabId="documents" className="documents">
                                    <div className="mx-3 my-4 documentDiv">
                                        <Row
                                            id="doc-header"
                                            className="doc-header flex-nowrap sticky bg-white"
                                        >
                                            <Col
                                                id="name_col"
                                                className="doc-name pl-0 col-xl-1 col-lg-1 col-md-1 col-sm-1"
                                                style={{ minWidth: '300px' }}
                                            ></Col>
                                            <Col
                                                id="doc-dmcname-col"
                                                className="doc-dmcname text-center col-xl-1 col-lg-1 col-md-1 col-sm-1"
                                            >
                                                <strong>DMC Name</strong>
                                            </Col>
                                            <Col
                                                id="doc-designelement-col"
                                                className="doc-designelement text-center col-xl-1 col-lg-1 col-md-1 col-sm-1"
                                            >
                                                <strong>Design Element</strong>
                                            </Col>
                                            <Col
                                                id="doc-artstyle-col"
                                                className="doc-artstyle text-center col-xl-1 col-lg-1 col-md-1 col-sm-1"
                                            >
                                                <strong>Art Style</strong>
                                            </Col>
                                            <Col
                                                id="doc-contenttype-col"
                                                className="doc-contenttype text-center col-xl-1  col-lg-1 col-md-1 col-sm-1"
                                            >
                                                <strong>Content Type</strong>
                                            </Col>
                                            <Col
                                                id="doc-characters-col"
                                                className="doc-characters text-center col-xl-2 col-lg-2 col-md-2 col-sm-2"
                                            >
                                                <strong>Characters</strong>
                                            </Col>
                                            <Col
                                                id="doc-keywords-col"
                                                className="doc-keywords text-center col-xl-2 col-lg-2 col-md-2 col-sm-2"
                                            >
                                                <strong>Keywords</strong>
                                            </Col>
                                            <Col className="doc-holiday text-center col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                                <strong>Holiday</strong>
                                            </Col>
                                        </Row>
                                        {documents ? (
                                            drawDocuments(documents, 0)
                                        ) : (
                                            <h4 className="text-center my-5">
                                                No documents to show.
                                            </h4>
                                        )}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </>
                    ) : null}
                </Col>
            </Row>
        </Loading>
    );
};

Project.propTypes = {
    match: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]).isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

export default Project;
