import { getStyleGuideDefaultValues } from '../../utils/api';

export function buildStyleGuideForm(form) {
    const styleGuideForm = {
        description: null,
        name: form.name,
        sections: buildStyleGuideSections(form.sections),
    };
    return styleGuideForm;
}

export async function setDefaultValues(fieldsByKey, onChange) {
    const brand_packaging_field = 'Design Element - Brand Packaging';
    const retail_field = 'Design Element - Retail';
    const style_guide_field = 'Design Element - Style Guide';
    const brand_packaging_id = 338958;
    const retail_id = 338966;
    const styleguide_id = 338967;

    let typeID;
    let typeField;

    if (fieldsByKey[brand_packaging_field]) {
        typeField = brand_packaging_field;
        typeID = brand_packaging_id;
    } else if (fieldsByKey[retail_field]) {
        typeField = retail_field;
        typeID = retail_id;
    } else if (fieldsByKey[style_guide_field]) {
        typeField = style_guide_field;
        typeID = styleguide_id;
    }

    const size = fieldsByKey['Style Guide Size'].value;

    if (!size || !typeID) {
        // console.log('exit');
        // console('size', size);
        // console('typeID', typeID);
        return;
    }

    const response = await getStyleGuideDefaultValues(typeID, size);
    if (response === '') {
        window.alert('There are no default values set for this Style Guide Size');
    } else {
        const numberOfDesignSets = response.numberOfDesignSets;
        const designElements: [] = response.designElements;

        if (!numberOfDesignSets || !designElements) {
            fieldsByKey['Number of Design Sets'].showError = true;
            return fieldsByKey;
        }

        fieldsByKey['Number of Design Sets'].showError = false;

        if (fieldsByKey['Number of Design Sets']) {
            fieldsByKey['Number of Design Sets'].value = numberOfDesignSets;
        }

        fieldsByKey[typeField].value = [];

        designElements.forEach((element) => {
            fieldsByKey[typeField].value.push(element.value);
            //avoid the case where a quantity field does not exist on the form
            if (fieldsByKey[element.label + ' - Quantity']) {
                fieldsByKey[element.label + ' - Quantity'].value = element.quantity;
                onChange(fieldsByKey[element.label + ' - Quantity']);
            }
        });
    }

    return fieldsByKey;
}

const buildStyleGuideSections = (sections) => {
    const out = [];
    sections.forEach((section) => {
        section.rows.forEach((row) => {
            row.fields.forEach((field) => {
                if (field.name === 'Style Guide Size') {
                    field.showStyleGuideButton = true;
                }
            });
        });
        out.push(section);
    });

    return out;
};
