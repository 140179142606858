import React, { useState, useEffect } from 'react';
import store from 'store2';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

const apiUrl = process.env.REACT_APP_API_URL;
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize
);

const FileUploadWrapper = styled.div`
    .filepond--file-action-button {
        cursor: pointer;
    }
    .filepond--drop-label {
        color: #555;
        min-height: 200px;
    }
    .filepond--label-action {
        text-decoration-color: #aaa;
    }
    .filepond--panel-root {
        background-color: #eee;
    }
    .filepond--panel-root {
        border-radius: 0.5em;
    }
    .filepond--item {
        width: calc(50% - 0.5em);
    }
    .filepond--item-panel {
        border-radius: 0.5em;
    }
    .filepond--item-panel {
        background-color: #555;
    }
    .filepond--drip-blob {
        background-color: #999;
    }
    .filepond--file-action-button {
        background-color: rgba(0, 0, 0, 0.5);
    }
    .filepond--file-action-button {
        color: white;
    }
    .filepond--file-action-button:hover,
    .filepond--file-action-button:focus {
        box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
    }
    .filepond--file {
        color: white;
    }
    [data-filepond-item-state*='error'] .filepond--item-panel,
    [data-filepond-item-state*='invalid'] .filepond--item-panel {
        background-color: #e21010;
    }
    [data-filepond-item-state='processing-complete'] .filepond--item-panel {
        background-color: #0c90f5;
    }
    .filepond--image-preview-overlay-success {
        color: #0c90f5;
    }
    .filepond--panel-root {
        background-color: transparent;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
    }
    .filepond--image-preview-wrapper {
        //border-radius: 0rem;
        //border: solid 1px #ced4da;
    }
    .filepond--wrapper {
        z-index: 1;
        position: relative;
    }
    .filepond--image-preview {
        background-color: #fff;
    }
`;

const FileUpload = (props) => {
    const { className, onChange } = props;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFileIds, setUploadedFileIds] = useState([]);

    const server = {
        url: apiUrl,
        process: {
            url: '/upload',
            method: 'POST',
            withCredentials: false,
            headers: {
                'X-WF-SESSION-ID': store.session('X-WF-SESSION-ID'),
            },
            timeout: 0,
            onload: null,
            onerror: null,
            ondata: (formData) => {
                const file = formData.getAll('filepond')[1];
                const newFormdata = new FormData();
                newFormdata.append('file', file, file.name);

                return newFormdata;
            },
        },
        revert: null,
    };

    useEffect(() => {
        onChange(uploadedFileIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFileIds]);

    return (
        <FileUploadWrapper className={`${className}`}>
            <FilePond
                files={uploadedFiles}
                allowMultiple
                server={server}
                maxFileSize="10mb"
                onupdatefiles={(files) => {
                    const returnIds = [];
                    files.map((file) => {
                        if (file.serverId) {
                            returnIds.push({
                                fileHandle: file.serverId,
                                filename: file.filename,
                            });
                        }

                        return null;
                    });
                    setUploadedFileIds(returnIds);
                    setUploadedFiles(files);
                }}
                onprocessfile={(error, file) => {
                    if (file.serverId) {
                        setUploadedFileIds((previousFileIds) => {
                            previousFileIds.push({
                                fileHandle: file.serverId,
                                filename: file.filename,
                            });

                            return previousFileIds;
                        });
                    }
                }}
            />
        </FileUploadWrapper>
    );
};

FileUpload.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
};
FileUpload.defaultProps = {
    className: '',
    onChange: () => {},
};

export default FileUpload;
