import React, { forwardRef } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';

library.add(faCalendar, faTimesCircle);

const DateTime = (props) => {
    const { value, isClearable, onChange, className, placeholder, dateFormat } = props;
    const ref = React.createRef();

    const useDateFormat = dateFormat === 'DATE' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss:SSS[Z]';

    const checkInput = (e) => {
        const inputValue = e.target.value.trim();
        let changeValue = null;
        if (moment(inputValue).isValid()) {
            changeValue = moment(inputValue).format(useDateFormat);
        }
        onChange(changeValue);
    };

    const CustomInput = forwardRef(({ value: newValue, onClick }, _ref) => (
        <InputGroup onClick={onClick} className={className}>
            <Input
                placeholder={placeholder}
                defaultValue={newValue}
                ref={_ref}
                onBlur={checkInput}
            />
            <InputGroupAddon addonType="append">
                {value && isClearable ? (
                    <InputGroupText>
                        <FontAwesomeIcon
                            icon={['far', 'times-circle']}
                            className="clickable hover-primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onChange(null);
                            }}
                        />
                    </InputGroupText>
                ) : null}
                <InputGroupText className="clickable">
                    <FontAwesomeIcon icon={['far', 'calendar']} />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    ));
    CustomInput.propTypes = {
        value: PropTypes.string,
        onClick: PropTypes.func,
    };
    CustomInput.defaultProps = {
        value: null,
        onClick: () => {},
    };
    CustomInput.displayName = 'CustomInput';

    return (
        <div className="d-flex">
            <DatePicker
                className="d-block"
                dateFormat="yyyy-MM-dd"
                onChange={(newDate) => onChange(moment(newDate).format(useDateFormat))}
                selected={value ? moment(value, useDateFormat).toDate() : null}
                customInput={<CustomInput ref={ref} />}
            />
        </div>
    );
};

DateTime.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    isClearable: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
};
DateTime.defaultProps = {
    value: null,
    isClearable: false,
    onChange: () => {},
    className: '',
    placeholder: null,
    dateFormat: 'DATE',
};

export default DateTime;
