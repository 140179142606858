import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const Header = () => (
    <Row>
        <Col className="px-0 text-center">
            <Link to="/">
                <img
                    src="/img/art/banner.jpg"
                    className="mx-auto w-100"
                    alt="Disney"
                    style={{ maxWidth: '1560px' }}
                />
            </Link>
        </Col>
    </Row>
);

export default Header;
