import Axios from 'axios';
import { toast } from 'react-toastify';
import store from 'store2';

const apiUrl = process.env.REACT_APP_API_URL || '/WEB_API/1.0.0';
const loginUrl = process.env.REACT_APP_API_LOGIN_URL || '';
const username = process.env.REACT_APP_AUTH_USERNAME;
const password = process.env.REACT_APP_AUTH_PASSWORD;
const env = process.env.NODE_ENV;
const apiAttempts = 1;

Axios.interceptors.request.use(
    (config) => {
        const newConfig = { ...config };
        const token = store.session('X-WF-SESSION-ID');
        if (token && token.length) {
            newConfig.headers['X-WF-SESSION-ID'] = token;
        }

        return newConfig;
    },
    (error) => Promise.reject(error)
);

let attempts = 0;

const getErrorMessage = (passedError) => {
    let error = 'An error has occurred, please refresh the page and try again.';

    if (passedError) {
        if (
            passedError.response &&
            passedError.response.data &&
            passedError.response.data.errorMessage
        ) {
            error = passedError.response.data.errorMessage;
        } else if (error.request) {
            // eslint-disable-next-line no-console
            console.error('Request error: ', error.request);
        } else if (passedError.message) {
            error = passedError.message;
        }
    }

    return error;
};

export async function getToken() {
    let token = store.session('X-WF-SESSION-ID');
    if (!token && env === 'development') {
        await Axios.post(
            `${loginUrl}/attask/api/v13.0/login?username=${username}&password=${password}`,
            {
                username,
                password,
            }
        )
            .then((response) => {
                token = response.data.data.sessionID;
                store.session('X-WF-SESSION-ID', token);

                return true;
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('getToken', error);

                return false;
            });
    }

    return token;
}

export async function getAppData() {
    const appData = await Axios.get(`${apiUrl}/getAppData`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getAppData', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getAppData();
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return appData;
}

export async function updateAssetDescription(assetId, description) {
    const appData = await Axios.put(`${apiUrl}/updateAssetDescription`, null, {
        params: {
            id: assetId,
            description,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/updateAssetDescription', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return updateAssetDescription(assetId, description);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function saveAssetMap(projectId, assetMapData) {
    const appData = await Axios.put(`${apiUrl}/saveAssetMapSettings`, assetMapData, {
        params: {
            id: projectId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/saveAssetMapSettings', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return saveAssetMap(projectId, assetMapData);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function getAssetMap(projectId) {
    const assetMapData = await Axios.get(`${apiUrl}/getAssetMap`, {
        params: {
            id: projectId,
        },
    })
        .then(
            (response) => response.data

            // console.error('REMOVE ME WHEN DONE');
            // return {
            //     projectName: response.data.projectName,
            //     designSets: [
            //         {
            //             ...response.data.designSets[0],
            //             designElements: response.data.designSets[0].designElements.concat(
            //                 response.data.designSets[0].designElements
            //             ),
            //         },
            //         {
            //             ...response.data.designSets[1],
            //             designElements: response.data.designSets[1].designElements.concat(
            //                 response.data.designSets[1].designElements
            //             ),
            //         },
            //     ],
            // };
        )
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getAppData', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getAssetMap(projectId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return assetMapData;
}

export async function getQueueDefinitions() {
    const appData = await Axios.get(`${apiUrl}/getQueueDefinitions`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/getQueueDefinitions', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getQueueDefinitions();
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function getCustomFormsForQueueTopic(topicId) {
    const appData = await Axios.get(`${apiUrl}/getCustomFormsForQueueTopic`, {
        params: {
            id: topicId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/getCustomFormsForQueueTopic', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getCustomFormsForQueueTopic(topicId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function searchKeywords(query) {
    const appData = await Axios.get(`${apiUrl}/searchKeywords`, {
        params: {
            query,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/searchKeywords', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return searchKeywords(query);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function searchTitles(query) {
    const appData = await Axios.get(`${apiUrl}/searchTitles`, {
        params: {
            query,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/searchTitles', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return searchTitles(query);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function getUserProjects(userID) {
    const projectData = await Axios.get(`${apiUrl}/getUserProjects`, {
        params: {
            userID,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getUserProjects', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getUserProjects(userID);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return projectData;
}

export async function searchUsers(query, roleID) {
    const appData = await Axios.get(`${apiUrl}/searchUsers`, {
        params: {
            query,
            roleID,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/searchUsers', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return searchUsers(query);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function searchCharacters(query, previousSearch, propertyIDs, page) {
    const appData = await Axios.get(`${apiUrl}/searchCharacters`, {
        params: {
            query,
            previousSearch,
            propertyIDs,
            page,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/searchCharacters', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return searchCharacters(query, previousSearch, propertyIDs, page);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function upload(fileName) {
    // eslint-disable-next-line no-console
    console.error('This is handled in the fileUpload component', fileName);
    // const appData = await Axios.post(`${apiUrl}/upload`, fileName)
    //     .then((response) => response.data)
    //     .catch(async (error) => {
    //         // eslint-disable-next-line no-console
    //         console.error('/upload', error);
    //         if (attempts < apiAttempts) {
    //             attempts++;
    //             const result = await getToken();
    //             if (result) {
    //                 return upload(fileName);
    //             }
    //         } else {
    //             toast.error(getErrorMessage(error), { autoClose: false });
    //         }

    //         return false;
    //     });

    // return appData;
}

export async function createRequest(requestObj) {
    const appData = await Axios.post(`${apiUrl}/createRequest`, requestObj)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/createRequest', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return createRequest(requestObj);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function getProject(projectId) {
    const projectData = await Axios.get(`${apiUrl}/getProject`, {
        params: {
            id: projectId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getProject', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getProject(projectId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return projectData;
}

export async function getRequest(requestId) {
    const data = await Axios.get(`${apiUrl}/getRequest`, {
        params: {
            id: requestId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getRequest', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getRequest(requestId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return data;
}

export async function getDmcData(projectId) {
    const dmcData = await Axios.get(`${apiUrl}/getDmcData`, {
        params: {
            id: projectId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getDmcData', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getDmcData(projectId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return dmcData;
}


export async function getDmcDataForRequest(requestId) {
    const dmcData = await Axios.get(`${apiUrl}/getDmcDataForRequest`, {
        params: {
            id: requestId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getDmcDataForRequest', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getDmcData(requestId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return dmcData;
}


export async function sendToDmc(projectId) {
    const dmcData = await Axios.put(`${apiUrl}/sendToDmc?id=${projectId}`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getDmcData', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getDmcData(projectId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return dmcData;
}

export async function getFolderStructure(projectId) {
    const folderData = await Axios.get(`${apiUrl}/getFolderStructure`, {
        params: {
            id: projectId,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getFolderStructure', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getFolderStructure(projectId);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return folderData;
}

export async function updateProject(projectId, projectObj) {
    const appData = await Axios.put(`${apiUrl}/updateProject/?id=${projectId}`, projectObj)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/updateProject', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return updateProject(projectId, projectObj);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}


export async function updateRequest(requestId, requestObj) {
    const appData = await Axios.put(`${apiUrl}/updateRequest/?id=${requestId}`, requestObj)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/updateRequest', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return updateRequest(requestId, requestObj);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function updateDocument(documentId, documentObj) {
    const appData = await Axios.put(`${apiUrl}/updateDocument?id=${documentId}`, documentObj)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/updateDocument', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return updateDocument(documentId, documentObj);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function getStyleGuideDefaultValues(styleGuideType, styleGuideSize) {
    const appData = await Axios.get(
        `${apiUrl}/getStyleGuideDefaultValues?styleGuideType=${styleGuideType}&styleGuideSize=${styleGuideSize}`
    )
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/getStyleGuideDefaultValues', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getQueueDefinitions();
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function generateDocuments(projectID) {
    const appData = await Axios.put(`${apiUrl}/generateDocuments?id=${projectID}`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/generateDocuments', error);
            toast.dismiss();
            if (error.response) {
                toast.error(error.response.data, { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function downloadDocument(documentId) {
    // window.location.href = `${apiUrl}/downloadActivities?ids=${activityIds.join(',')}`;

    let download = null;
    await Axios.get(`${apiUrl}/getDownloadUrl`, {
        params: {
            id: documentId,
        },
    })
        .then((response) => {
            download = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('downloadActivities', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return download;
}

export async function getResourcePlanAppData() {
    let response = null;
    await Axios.get(`${apiUrl}/getResourcePlanAppData`, {})
        .then((internalResponse) => {
            response = internalResponse.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('downloadActivities', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return response;
}

export async function getProjectsAndResources(formFields) {
    let response = null;
    await Axios.get(`${apiUrl}/getProjectsAndResources`, {
        params: formFields,
    })
        .then((internalResponse) => {
            response = internalResponse.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('downloadActivities', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return response;
}

export async function updateRoleResources(projectID, roleID, obj) {
    const appData = await Axios.put(`${apiUrl}/updateRoleResources`, obj, {
        params: {
            projectID,
            roleID,
        },
    })
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/updateRoleResources', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return updateRoleResources(projectID, roleID, obj);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function markProjectReady(projectID, state) {
    const appData = await Axios.put(
        `${apiUrl}/markProjectReady`,
        {},
        {
            params: {
                id: projectID,
                isReady: state,
            },
        }
    )
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/markProjectReady', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return markProjectReady(projectID, state);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}

export async function finalizeProject(projectID) {
    const appData = await Axios.put(
        `${apiUrl}/finalizeProject`,
        {},
        {
            params: {
                id: projectID,
            },
        }
    )
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/finalizeProject', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return finalizeProject(projectID);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return appData;
}


export async function sendToPLM(plmDTO) {
    const response = await Axios.put((`${apiUrl}/sendToPlm/`), plmDTO,
        {}
    )
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('/sendToPlm', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return sendToPLM(plmDTO);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
            }

            return false;
        });

    return response;
}



export async function getPLMDocuments(projectID) {

    const plmDocuments = await Axios.get(`${apiUrl}/getPlmDocuments/${projectID}`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getPLMDocuments', error);
            if (attempts < apiAttempts) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getPLMDocuments(projectID);
                }
            } else {
                toast.dismiss();
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '?ERROR=true';
            }

            return false;
        });

    return plmDocuments;
}

